import React, { useState } from "react";
import { toast } from "react-toastify";
import { useAccountRequests } from "../../../../api/grpc/account/useAccountRequests";
import { useWorkplacesClient } from "../../../../api/grpc/workplaces/useWorkplacesClient";
import { useAuthContext } from "../../../../lib/context/Auth/AuthContext";
import useGrpcQuery from "../../../../lib/hooks/useGrpcQuery";

import { tooltips } from "../../../../lib/utils/tooltips";
import {
  DesksListResponse,
  SearchAccountDesksRequest,
} from "../../../../api/grpc/workplaces/workplaces";
import { FormSection } from "../../../shared/FormSection/FormSection";
import { LabelWithInformationBox } from "../../../shared/LabelWithInformationBox/LabelWithInformationBox";
import { LoadingBox } from "../../../shared/LoadingBox/LoadingBox";
import { Switch } from "../../../shared/Switch/Switch";

export const GeneralSettings = () => {
  const [disabled, setDisabled] = useState(false);

  const { user, updateUser } = useAuthContext();
  const { getAssignedDesks } = useWorkplacesClient();
  const { updateAllowedToFind } = useAccountRequests();
  const { loading, error, data } = useGrpcQuery<
    SearchAccountDesksRequest,
    DesksListResponse
  >({
    method: getAssignedDesks,
    variables: {
      accountID: "",
      customerID: "",
    },
  });

  const handleAllowToFind = async (allowToFind: boolean) => {
    try {
      setDisabled(true);

      await updateAllowedToFind(allowToFind);
      updateUser({ allowToFind: allowToFind });

      toast.success("Allow people to find me updated successully.");
    } catch (error: any) {
      toast.error(
        error.message
          ? error.message
          : "Couldn't update allow to find feature, please reload try again!"
      );
    } finally {
      setTimeout(() => {
        setDisabled(false);
      }, 2000);
    }
  };

  return (
    <FormSection
      title="General settings"
      className="FormSection__generalSettings"
      toggleable
    >
      <label className="label__main">Notifications</label>
      <Switch
        title="Allow to send me notifications"
        name="notifications"
        onChange={() => {}}
        message={tooltips.generalSettings.allowNotifications}
        value={false}
        className="Switch__border"
      />

      <label className="label__main pt-3">Permissions</label>
      <div className="Switch__border">
        <Switch
          title="Allow people to find me"
          name="findMe"
          onChange={(value: boolean) => {
            handleAllowToFind(value);
          }}
          message={tooltips.generalSettings.findMe}
          value={user?.allowToFind}
          disabled={disabled}
        />
        <div className="GeneralSettings__desks">
          <LabelWithInformationBox
            title="Assigned desk"
            message={tooltips.generalSettings.assignedDesk}
            className="GeneralSettings__desks--label"
          />

          {loading ? (
            <LoadingBox maxWidth={300} minHeight={130} />
          ) : error ? (
            <div>
              Error while loading assigned desks, please reload and try again
            </div>
          ) : (
            <div className="GeneralSettings__list">
              {data?.desks.map((desk) => (
                <span className="GeneralSettings__list--desk" key={desk.id}>
                  {desk.name}
                </span>
              ))}
            </div>
          )}
        </div>
      </div>
    </FormSection>
  );
};
