import React from "react";
import { MainResources } from "../../../../lib/types/main.types";
import { Icon } from "../../../shared/Icon/Icon";
import { ReservationCardElement } from "./ReservationCardElement";
import { DeskLocationItem } from "../../../../api/grpc/desk/desk";
import { LocationPath } from "../../../Bookings/shared/LocationPath/LocationPath";

interface Props {
  type: MainResources;
  name: string;
  tags: string;
  locationPath: DeskLocationItem[];
}

export const ReservationCardHeader = ({
  type,
  tags,
  name,
  locationPath,
}: Props) => {
  return (
    <div className="ReservationCardHeader">
      <div className="flex-a-center ReservationCard__head justify-content-between mb-3">
        <div className="flex-a-center ReservationListCard__icon overflow-text">
          <Icon icon={type === "room" ? "rooms-icon" : "desk_icon_18x18"} />
          <h6 className="pl-2 pr-2 overflow-text text-black">{name}</h6>
        </div>

        {type === "room" && (
          <div className="flex-a-center">
            <Icon icon="user-rounded" />
            <h6 className="pl-2 text-pro pt-1">6</h6>
          </div>
        )}
      </div>

      <ReservationCardElement
        icon="tags-mobile"
        text={tags.length > 0 ? tags : "-"}
        className="mb-2"
      />

      {type === "room" ? (
        <ReservationCardElement icon="equipment-mobile" text="Focus room" />
      ) : (
        <ReservationCardElement
          icon="location-icon"
          text={locationPath.length > 0 ? undefined : "Root level"}
          element={
            locationPath.length > 0 ? (
              <LocationPath location={locationPath} />
            ) : undefined
          }
        />
      )}
    </div>
  );
};
