import React, { useState } from "react";
import { toast } from "react-toastify";

import {
  useCustomerContext,
  useLicensesContext,
} from "../../../../../lib/context/Customer/CustomerContext";
import { useDeskFormik } from "../../../../shared/Forms/DeskForm/lib/useDeskFormik";
import { useWorkplaceRequests } from "../../../../../api/graphql/useWorkplaceRequests";
// import { useSubscriptionRequests } from "../../../../../api/grpc/subscription/useSubscriptionRequests";
import { useCreateBulkDesksHelper } from "../../helpers/createBulkDesksHelper";
import { useGetContacts } from "../../../../People/lib/useGetContacts";
import { useWorkingHoursData } from "../../../../Account/helpers/useWorkingHoursData";
import { useDefineLicense } from "../../../../../lib/hooks/useDefineLicense";
import { useDeskTemplateContext } from "../../../../../lib/context/Templates/DeskTemplate/DeskTemplateContext";

import { WorkplaceModalProps } from "../AddWorkplaceModal";

import { DeskForm } from "../../../../shared/Forms/DeskForm/DeskForm";
import ModalSidebar from "../../../../Modals/ModalSidebar/ModalSidebar";
import { AddDeskVariables } from "../../../../../api/graphql/desks/desks";
import { EntityType } from "../../../../../api/grpc/subscription/subscription";

export const AddDeskModal = (props: WorkplaceModalProps) => {
  const { isOpen, toggle, parentId, parentType } = props;

  const { addDesk } = useWorkplaceRequests();
  const { retrieveTenant, customer } = useCustomerContext();
  const { handleLicenseUpdate } = useLicensesContext();
  // const { licenseFreeSlots } = useSubscriptionRequests();
  const [loading, setLoading] = useState<boolean>(false);
  const { createBulkDesksHelper } = useCreateBulkDesksHelper();
  const { customerSettings, error: workingHoursError } = useWorkingHoursData();
  const { defineLicenseNumber, refetch: refetchLicenseNumber } =
    useDefineLicense(EntityType.DESK);
  const {
    loading: loadingContacts,
    error,
    data: dataContacts,
  } = useGetContacts({
    page: 1,
    pageSize: 0,
  });
  const { deskTemplates, loadingDeskTemplate, errorDeskTemplates } =
    useDeskTemplateContext();

  const formik = useDeskFormik({
    onSubmit: async (values) => {
      try {
        if (!!workingHoursError) {
          toast.error(
            "Failed to fetch working hours, please contact your administrator."
          );
          return;
        }

        setLoading(true);

        const { id, tenantId } = await retrieveTenant();

        // if (values.licensed && !values.desksNumber) {
        //   const {
        //     response: { quantity },
        //   } = await licenseFreeSlots(EntityType.DESK);

        //   if (typeof quantity !== "number") {
        //     throw new Error("Licenses couldn't be loaded, please try again!");
        //   }

        //   if (quantity === 0) {
        //     throw new Error(
        //       "You've exceeded the quantity of licenses for desks!"
        //     );
        //   }
        // }

        // in account below, we have to split in accountID and email, because
        // we store accountId and email values in same string with a space between

        const emailAndIdOfAccount = values?.account?.accountID.split(" ") || [];
        const idOfAccount = emailAndIdOfAccount[0];
        const emailOfAccount = emailAndIdOfAccount[1];

        const variables: AddDeskVariables = {
          id: parentId || id,
          tenantId,
          name: values.name,
          tags: values.tags || [],
          isBooked: values.isBooked,
          marker: { latitude: 0, longitude: 0 },
          showCheckInTime: values.showCheckInTime,
          checkInTime: values.checkInTime,
          qrCodeRequired: values.qrCodeRequired,
          isBlocked: values.isBlocked,
          description: values.description,
          workingHrsActivated: values.workingHrsActivated,
          workingHrsStart: values.workingHrsStart,
          workingHrsEnd: values.workingHrsEnd,
          workingWeekDays: !!customerSettings?.workingWeekDays.length
            ? customerSettings.workingWeekDays
            : [1, 2, 3, 4, 5],
          account: values?.account?.accountID
            ? {
                accountID: idOfAccount,
                displayName: values.account.displayName,
                tenantID: customer.tenantId,
                email: emailOfAccount || values.account?.email,
              }
            : null,
        };

        if (values.desksNumber && values.desksNumber >= 2) {
          const bulkDesksResponse = await createBulkDesksHelper({
            id: id,
            parentId: parentId,
            parentType: parentType,
            variables: variables,
            values: values,
            tenantId: tenantId,
          });

          if (!!bulkDesksResponse?.length) {
            toast.warn(bulkDesksResponse);
          }

          toggle();
          toast.success("Desks added successfully!");
          return;
        }

        const handleAddDesk = async () => {
          try {
            const response = await addDesk(variables, parentType);
            const deskId = response.data?.addDesk.desk[0].id;

            if (values.licensed && deskId) {
              await handleLicenseUpdate({
                id: deskId,
                type: EntityType.DESK,
                value: values.licensed,
              });
            }

            toggle();

            toast.success("Desk added successfully!");
          } catch (error: any) {
            toast.error(
              error.networkError.result.message
                ? error.networkError.result.message
                : "Couldn't add desk, please reaload and try again!"
            );
            setLoading(false);
            return;
          }
        };

        await handleAddDesk();
      } catch (error: any) {
        toast.error(error.toString());
        setLoading(false);
        console.error(error.message);
      }
    },
  });

  const fillFieldsFromProfile = (id: string) => {
    if (loadingDeskTemplate) {
      return;
    }

    if (errorDeskTemplates) {
      return toast.error(
        errorDeskTemplates ||
          "Cannot fill fields from profile due to error fetching them!"
      );
    }

    const desk = deskTemplates.find((desk) => desk.id === id);

    if (!desk) {
      return toast.error("Desk profile not found!");
    }

    formik.setFieldValue("description", desk.description);
    formik.setFieldValue("tags", desk.deskSettings?.tags);
    formik.setFieldValue("isBlocked", desk.deskSettings?.isBlocked);
    formik.setFieldValue("account", desk.deskSettings?.account);
    formik.setFieldValue("showCheckInTime", desk.deskSettings?.showCheckInTime);
    formik.setFieldValue("checkInTime", desk.deskSettings?.checkInTime);
    formik.setFieldValue(
      "workingHrsActivated",
      desk.deskSettings?.workingHrsActivated
    );
    formik.setFieldValue("workingHrsStart", desk.deskSettings?.workingHrsStart);
    formik.setFieldValue("workingHrsEnd", desk.deskSettings?.workingHrsEnd);
    formik.setFieldValue("qrCodeRequired", desk.deskSettings?.qrCodeRequired);
  };

  return (
    <ModalSidebar
      isOpen={isOpen}
      loading={loading}
      onToggle={toggle}
      title="Add new Desk"
      onSubmit={formik.handleSubmit}
    >
      <DeskForm
        formik={formik}
        informationBoxDirection="left"
        hasBulkDesks
        loading={loadingContacts}
        error={error}
        data={dataContacts}
        defineLicenseNumber={defineLicenseNumber}
        refetchLicenseNumber={refetchLicenseNumber}
        fillFieldsFromProfile={fillFieldsFromProfile}
      />
    </ModalSidebar>
  );
};
