import React from "react";
import { Modal, ModalFooter, ModalHeader } from "reactstrap";
import { LocationItem } from "../../../../api/grpc/workplaces/workplaces";
import { tooltips } from "../../../../lib/utils/tooltips";
import { Button } from "../../../shared/Button/Button";
import { Icon, IconDefinition } from "../../../shared/Icon/Icon";
import { InformationBox } from "../../../shared/InformationBox/InformationBox";
import { BookingsModalBody } from "../../BookingsMap/BookingsMapModal/shared/BookingsModalBody";

import "./BookingsModal.scss";

interface Props {
  title: string;
  isOpen: boolean;
  toggle: () => void;
  icon?: IconDefinition;
  workspaceIconType: IconDefinition;
  workspaceName: string;
  day: string;
  startTime: string;
  endTime: string;
  userName: string;
  subject?: string;
  locationPath: LocationItem[];
  handleModalClose?: () => void;
  type: "confirm" | "delete" | "check-in" | "update";
  buttonTitle?: string;
  buttonClick?: () => void;
  loadSpinner?: boolean;
  loadTitle?: string;
  updateTime?: Date;
  handleUpdateTime?: (date: Date) => void;
  classNames?: string;
  disableButton?: boolean;
  allDay?: boolean;
  timeZone?: string;
  isAttendee?: boolean;
  allowToModifyEvent?: boolean;
}

export const BookingsModal = ({
  title,
  isOpen,
  toggle,
  icon,
  workspaceIconType,
  workspaceName,
  day,
  startTime,
  endTime,
  userName,
  subject,
  locationPath,
  handleModalClose,
  type,
  buttonTitle,
  buttonClick,
  loadSpinner,
  loadTitle,
  updateTime,
  handleUpdateTime,
  classNames,
  disableButton,
  allDay,
  timeZone,
  isAttendee,
  allowToModifyEvent,
}: Props) => {
  return (
    <Modal isOpen={isOpen} onToggle={toggle} className="BookingsModal">
      <ModalHeader>
        <span onClick={handleModalClose} className="btn-close">
          <Icon icon="close-icon" />
        </span>
      </ModalHeader>

      <BookingsModalBody
        title={title}
        icon={icon}
        workspaceName={workspaceName}
        workspaceIconType={workspaceIconType}
        day={day}
        startTime={startTime}
        endTime={endTime}
        userName={userName}
        locationPath={locationPath}
        type={type}
        updateTime={updateTime}
        handleUpdateTime={handleUpdateTime}
        allDay={allDay}
        timeZone={timeZone}
        subject={subject}
        isAttendee={isAttendee}
        allowToModifyEvent={allowToModifyEvent}
      />

      {type === "confirm" || isAttendee || !allowToModifyEvent ? (
        <>
          <ModalFooter>
            <Button
              size="small"
              color="primary"
              title="Ok"
              onClick={handleModalClose}
            />

            {allowToModifyEvent && !isAttendee && (
              <div className="flex-a-center">
                <InformationBox message={tooltips.bookings.info} />
                <span className="label__main pl-min BookingsModal__required">
                  Check-in may be required
                </span>
              </div>
            )}
          </ModalFooter>
        </>
      ) : (
        <>
          <ModalFooter className={classNames}>
            <Button
              size="small"
              color="outline-secondary"
              title="Cancel"
              onClick={handleModalClose}
            />

            <Button
              size="small"
              color="primary"
              title={buttonTitle}
              onClick={buttonClick}
              disabled={disableButton}
              loadSpinner={loadSpinner}
              loadTitle={loadTitle}
            />
          </ModalFooter>
        </>
      )}
    </Modal>
  );
};
