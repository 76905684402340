import React from "react";
import { trimText } from "../../../../lib/helpers/trimText";
import { Icon } from "../../../shared/Icon/Icon";
import { Button } from "../../../shared/Button/Button";

import { PopUpTags } from "../BookingsMapModal/shared/PopUpTags";
import { PopUpAvailablitity } from "../BookingsMapModal/shared/PopUpAvailablitity";

import { BusyTimeSlots } from "../../../../api/grpc/workplaces/ggevent/ggevent";

import "./BookingsMapPopUp.scss";

interface Props {
  id: string;
  name: string;
  tags: string[];
  isBooked: boolean;
  isAssigned: boolean;
  isBlocked: boolean;
  type: string;
  busySlots: BusyTimeSlots[];
  handleOpenModal: () => void;
  username?: string;
  location?: string;
  email?: string;
}

export const getReserveButtonId = (id: string) => {
  return `reserve-button-${id}`;
};

export const BookingsMapPopUp = ({
  id,
  isBooked,
  isAssigned,
  isBlocked,
  location,
  type,
  name,
  tags,
  username,
  busySlots,
  handleOpenModal,
  email,
}: Props) => {
  return (
    <>
      <div className="BookingsMapPopUp">
        <div className="BookingsMapPopUp__content">
          <span className="BookingsMapPopUp__title">{trimText(name, 25)}</span>
          <span className="BookingsMapPopUp__location d-block"></span>

          {isAssigned || isBlocked ? (
            <></>
          ) : (
            <PopUpAvailablitity isBooked={isBooked} busySlot={busySlots} />
          )}

          {type === "Desk" &&
            (busySlots[0]?.organizer?.allowToFind === true ||
              email === busySlots[0]?.organizer?.email) && (
              <div className="pt-2 flex-a-center justify-content-center">
                <Icon icon="single-user-icon" />
                <span className="pl-min">{!!username ? username : "-"}</span>
              </div>
            )}
        </div>

        <div className="BookingsMapPopUp__divider" />

        {(type === "Desk" && !isAssigned) || type === "Room" ? (
          <>{tags.length > 0 && <PopUpTags tags={tags} />}</>
        ) : (
          <></>
        )}
        {(isBooked && email !== busySlots[0].organizer?.email) || isBlocked ? (
          <></>
        ) : (
          <>
            {!(type === "Desk" && isAssigned) && (
              <Button
                id={getReserveButtonId(id)}
                color={isBooked ? "outline-secondary" : "primary"}
                size="small"
                title={isBooked ? "Delete" : "Reserve"}
                icon={isBooked ? "delete-reservation" : "bookings-icon"}
                className="BookingsMapPopUp__button--reserve"
                onClick={handleOpenModal}
              />
            )}
          </>
        )}
      </div>
    </>
  );
};
