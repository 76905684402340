import React from "react";
import { PageHeader } from "../shared/PageHeader/PageHeader";
import { TimeZoneLabel } from "../Insights/shared/TimeZoneLabel/TimeZoneLabel";
import { AllReservationsDescription } from "./shared/AllReservationsDescription";
import { AllReservationsHeaderButtons } from "./shared/AllReservationsHeaderButtons/AllReservationsHeaderButtons";
import { AllReservationsContent } from "./AllReservationsContent/AllReservationsContent";
import { AllReservationContextProvider } from "./shared/context/AllReservationContext/AllReservationContextProvider";

import "./AllReservations.scss";

export const AllReservationsRoute = () => {
  return (
    <AllReservationContextProvider>
      <div className="Reservations AllReservations default-page">
        <div className="position-relative mb-4 pb-3 page-border-bottom">
          <PageHeader title="All reservations" hideBorder showUserViewType>
            <TimeZoneLabel />
          </PageHeader>

          <div className="d-flex justify-content-between align-items-start">
            <AllReservationsDescription />

            <AllReservationsHeaderButtons />
          </div>
        </div>

        <AllReservationsContent />
      </div>
    </AllReservationContextProvider>
  );
};
