import React, { useState } from "react";
import { useTimeZoneContext } from "../../../../lib/context/TimeZone/TimeZoneContext";
import { useCalendarContext } from "../../../../lib/context/Calendar/CalendarContext";
import { useDeskBookingRequest } from "../../../../api/grpc/desk/useDeskBookingRequest";
import { useCheckOutRoom } from "../helpers/useCheckOutRoom";
import { utcToZonedTime } from "date-fns-tz";

import { Event as DeskEvent } from "../../../../api/grpc/desk/ggevent/ggevent";
import { Event as RoomEvent } from "../../../../api/grpc/booking/ggevent/ggevent";
import { dateToHoursAndMinutes } from "../../lib/dateInputConvert";
import { useFormatReservationDate } from "../../lib/datePickerHelper";
import { BookingsModal } from "../../shared/BookingsModal/BookingsModal";
import { toast } from "react-toastify";
import { ExtendedProps } from "../../Reservations/ReservationList/ReservationListActions";

export const CheckOutModal = ({
  type,
  workplaceId,
  event,
  startTime,
  endTime,
  name,
  locationPath,
  isOpen,
  toggleModal,
  userName,
  refetchDesk,
  refetchRoom,
  allowToModifyEvent,
}: ExtendedProps) => {
  const [loading, setLoading] = useState(false);
  const { timeZone } = useTimeZoneContext();
  const { checkOutDesk } = useDeskBookingRequest();
  const { checkOutRoom } = useCheckOutRoom();
  const { personalCalendar } = useCalendarContext();

  const definedUsername =
    type === "desk" || !!!personalCalendar.length
      ? userName
      : event.organizer?.displayName || "";

  const handleCheckInDesk = async () => {
    try {
      setLoading(true);
      if (type === "desk") {
        await checkOutDesk(event?.metaData?.DESK_EVENT_ID);

        toast.success("Check-out finished successfully");

        if (refetchDesk) {
          refetchDesk();
        }

        return;
      }

      await checkOutRoom(workplaceId, event as RoomEvent);

      toast.success("Check-out finished successfully");

      if (refetchRoom) {
        refetchRoom();
      }
    } catch (error: any) {
      setLoading(false);
      toast.error(
        error?.message || "Check-out failed, please reaload and try again"
      );
    }
  };

  return (
    <BookingsModal
      isOpen={isOpen}
      toggle={toggleModal}
      title="Check out?"
      icon="check-out-reservation"
      workspaceIconType={type === "desk" ? "desk-reserve" : "room-reserve"}
      workspaceName={name}
      day={useFormatReservationDate(
        utcToZonedTime(new Date(startTime), timeZone)
      )}
      // day={useFormatReservationDate(new Date(startTime))}
      startTime={dateToHoursAndMinutes(new Date(startTime), timeZone)}
      endTime={
        type === "desk" && event.allDay === true
          ? "00:00"
          : dateToHoursAndMinutes(new Date(endTime), timeZone)
      }
      userName={definedUsername}
      locationPath={locationPath}
      type="check-in"
      buttonClick={handleCheckInDesk}
      buttonTitle="Check out"
      handleModalClose={toggleModal}
      classNames="modal-footer-row"
      subject={event?.title}
      disableButton={loading}
      allDay={event?.allDay}
      allowToModifyEvent={allowToModifyEvent}
    />
  );
};
