import React, { PropsWithChildren } from "react";
import { Button } from "../../../shared/Button/Button";

export const AddReservation = ({
  handleClick,
}: PropsWithChildren<{
  handleClick: () => void;
}>) => {
  return (
    <div className="AddReservation mt-4 mb-2">
      <div className="divider"></div>

      <Button onClick={handleClick} icon="plus-icon" />

      <div className="divider"></div>
    </div>
  );
};
