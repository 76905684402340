import React, { useState } from "react";
import { ReservationsDateRange } from "./ReservationsDateRange";
import { Button } from "../../../shared/Button/Button";
import cs from "classnames";
import { AdjustableButtons } from "../../../shared/AdjustableButtons/AdjustableButtons";
import { useAllReservationContext } from "../context/AllReservationContext/AllReservationContext";
import { AllReservationWorkspacePicker } from "./AllReservationWorkspacePicker";

export const AllReservationsHeaderButtons = () => {
  const { openWorkspacePicker, setOpenWorkspacePicker } =
    useAllReservationContext();

  return (
    <>
      {openWorkspacePicker && (
        <>
          <AllReservationWorkspacePicker />
          <div
            onClick={() => setOpenWorkspacePicker(false)}
            className="background__shadow position-fixed"
          ></div>
        </>
      )}
      <div className="flex-a-center gap-1">
        <AdjustableButtons>
          <ReservationsDateRange />

          <Button
            title="Select calendars"
            color="third"
            size="medium"
            onClick={() => {
              if (!openWorkspacePicker) {
                setOpenWorkspacePicker(true);
              }
            }}
            icon="eye-default"
            className="CalendarSelector"
          />
        </AdjustableButtons>
      </div>
    </>
  );
};
