import React, { useEffect, useState } from "react";
import { addMinutes, setHours, setMinutes, subMinutes } from "date-fns";
import { useToggleModal } from "../../../../../lib/hooks/useToggleModal";
import set from "date-fns/set";
import { FormGroup, Label } from "reactstrap";
import DatePicker from "react-datepicker";
import { toast } from "react-toastify";

import { CustomerSettings } from "../../../../../api/grpc/customer/customer";
import { tooltips } from "../../../../../lib/utils/tooltips";
import { CustomerSettingsVariables } from "../../../../Organization/OrganizationSettings/InsightsCard/Insights/Insights";
import { WorkHours } from "../../../../Organization/OrganizationSettings/BookingAndNotificationsCard/WorkHours/WorkHours";
import { timeFormat } from "../../../../Bookings/helpers/displayTimeFormat";
import { Icon } from "../../../Icon/Icon";
import { InformationBox } from "../../../InformationBox/InformationBox";
import { Input } from "../../../Input/Input";
import { CustomerSettingsUpdate } from "../../../../../api/grpc/customer/useCustomerRequests";

interface Props {
  handleStartHours: (value: string | Date) => void;
  handleEndHours: (value: string | Date) => void;
  startHours: string | Date;
  endHours: string | Date;
  onChange?: () => void;
  isGlobal?: boolean;
  customerSettings?: CustomerSettings;
  updateCustomerSettings: ({
    startTime,
    endTime,
    errorReportingEmail,
    meetingSpaceCostPerHr,
    currency,
    workingWeekDays,
    collectInsights,
    ssoEnable,
    disableDeskSimultaneous,
    showEventOrganizer,
  }: CustomerSettingsUpdate) => Promise<any>;
  loading: boolean;
  refetch: () => void;
  id?: string;
  disabledWorkingHours?: boolean;
  isProfile?: boolean;
}

export const WorkingHoursForm = ({
  handleStartHours,
  handleEndHours,
  startHours,
  endHours,
  onChange,
  isGlobal,
  customerSettings,
  updateCustomerSettings,
  loading,
  refetch,
  id,
  disabledWorkingHours,
  isProfile,
}: Props) => {
  const [overrideWorkingHours, setOverrideWorkingHours] = useState(isGlobal);
  const { isOpen, toggleModal } = useToggleModal();

  useEffect(() => {
    setOverrideWorkingHours(isGlobal);
  }, [isGlobal, id]);

  const handleWorkingHours = async ({
    startTime,
    endTime,
    workingWeekDays,
  }: CustomerSettingsVariables) => {
    try {
      if (!!customerSettings) {
        await updateCustomerSettings({
          startTime: startTime ? startTime : "",
          endTime: endTime ? endTime : "",
          errorReportingEmail: customerSettings.errorReportingEmail,
          meetingSpaceCostPerHr: customerSettings.meetingSpaceCostPerHr,
          currency: customerSettings.currency,
          workingWeekDays: workingWeekDays
            ? workingWeekDays
            : customerSettings.workingWeekDays,
          collectInsights: customerSettings.collectInsigths,
          ssoEnable: customerSettings.ssoEnable,
          disableDeskSimultaneous: customerSettings.disableDeskSimultaneous,
          showEventOrganizer: customerSettings.showEventOrganizer,
        });

        toast.success("Working hours updated successfuly");

        refetch();

        toggleModal();

        return;
      }

      // toast.success("Working hours configured successfuly");
    } catch (error: any) {
      toast.error(
        error.message
          ? error.message
          : "Cannot set working hours, please reload and try again!"
      );
    }
  };

  return (
    <div className="WorkingHoursForm">
      <fieldset className="flex-a-center WorkingHoursForm__selectors">
        <FormGroup>
          <label>Work hour from</label>
          <div
            className={
              overrideWorkingHours ? "d-initial" : "d-initial disabled-arrow"
            }
          >
            <DatePicker
              selected={set(new Date(), {
                hours: new Date(startHours || "").getHours(),
                minutes: new Date(startHours || "").getMinutes(),
                seconds: 0,
              })}
              onChange={(date) => {
                if (!date) {
                  return;
                }
                handleStartHours(date);

                if (date >= endHours) {
                  handleEndHours(addMinutes(new Date(date), 15));
                }

                return;
              }}
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={15}
              timeCaption="Time"
              timeFormat={timeFormat}
              dateFormat={timeFormat}
              disabled={!overrideWorkingHours || disabledWorkingHours}
            />
          </div>
        </FormGroup>

        <FormGroup>
          <label>Work hour to</label>
          <div
            className={
              overrideWorkingHours ? "d-initial" : "d-initial disabled-arrow"
            }
          >
            <DatePicker
              selected={set(new Date(), {
                hours: new Date(endHours || "").getHours(),
                minutes: new Date(endHours || "").getMinutes(),
                seconds: 0,
              })}
              onChange={(date) => {
                if (!date) {
                  return;
                }
                handleEndHours(date);

                if (date <= startHours) {
                  handleStartHours(subMinutes(new Date(date), 15));
                }

                return;
              }}
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={15}
              timeCaption="Time"
              timeFormat={timeFormat}
              dateFormat={timeFormat}
              disabled={!overrideWorkingHours || disabledWorkingHours}
              className="react-datepicker__input-container--disabled"
              injectTimes={[setHours(setMinutes(new Date(), 59), 23)]}
            />
          </div>
        </FormGroup>

        <FormGroup className="position-relative">
          <Input
            type="checkbox"
            id="updateWorkHours"
            name="updateWorkHours"
            data-cy="updateWorkHours"
            checked={overrideWorkingHours}
            onChange={() => {
              if (disabledWorkingHours) {
                return;
              }

              setOverrideWorkingHours((prev) => !prev);
              onChange && onChange();
            }}
          />
          <Label for="updateWorkHours" className="checkbox_label">
            <span>Override global work hours</span>
            <span className="checkbox_box"></span>
          </Label>
          <InformationBox message={tooltips.workingsHours} direction="left" />
        </FormGroup>
      </fieldset>

      {/* {!isProfile && ( */}
      <div className="flex-a-center WorkingHoursForm__redirect">
        <div
          className={`flex-a-center ${overrideWorkingHours ? "disabled" : ""}`}
          onClick={() => {
            if (disabledWorkingHours || overrideWorkingHours) {
              return;
            }

            return toggleModal();
          }}
        >
          <Icon icon="edit-icon" className="mr-2" />
          <span className="label__main pt-1">
            Edit global work hours & days
          </span>
        </div>

        <InformationBox
          direction="right"
          message={tooltips.editWorkHours}
          className="pl-2"
        />
      </div>
      {/* )} */}
      {isOpen && (
        <WorkHours
          isOpen={isOpen}
          toggleModal={toggleModal}
          loading={loading}
          data={customerSettings}
          handleWorkingHours={handleWorkingHours}
        />
      )}
    </div>
  );
};
