import React from "react";
import { useMemo, useRef } from "react";
import L from "leaflet";
import { useBookingsMapContext } from "../Context/BookingsMapContext";
import {
  FloorChildrenType,
  useMarkerData,
} from "../../../Floors/lib/floorData";
import { floorPlanHelpers } from "../../../Floors/lib/floorPlanHelpers";
import { renderToStaticMarkup } from "react-dom/server";

import { UserLeafletMarker } from "../../../shared/UserLeafletMarker/UserLeafletMarker";
import { BusyTimeSlots } from "../../../../api/grpc/workplaces/ggevent/ggevent";
import { MarkerSettings } from "../../../Floors/lib/useMarkerData";

interface MarkerType {
  id: string;
  marker: L.Marker;
  isBooked: boolean;
  isAssigned: boolean;
  isBlocked: boolean;
  busySlot: BusyTimeSlots[];
  type: string;
  location: string;
  name: string;
  tags: string[];
  username: string;
  attachedUserPopup?: boolean;
  attendeeImage: string;
}

const redBorderColor = "#EA1D25";
const greenBorderColor = "#00EC95";

const redFillColor = "#F9BBB3";
const greenFillColor = "#B3F9F0";

export const useBookingsMarkers = (
  rooms: FloorChildrenType[],
  desks: FloorChildrenType[],
  settings?: MarkerSettings
) => {
  const { markerData, cirleData, polygonalData } = useMarkerData(
    rooms,
    desks,
    [],
    settings
  );
  const markersCacheRef = useRef<MarkerType[]>([]);
  const circleCacheRef = useRef<L.Circle[]>([]);
  const polygonCacheRef = useRef<L.Polygon[]>([]);

  const { searchedAttendees } = useBookingsMapContext();
  const { getFloorEntityLeafletIcon } = floorPlanHelpers();

  const shapes = useMemo<{
    _circles: L.Circle[];
    _polygonal: L.Polygon[];
  }>(() => {
    // clear cached circles
    circleCacheRef.current.forEach((cachedCircle) => {
      cachedCircle.remove();
    });

    polygonCacheRef.current.forEach((cachedPolygon) => {
      cachedPolygon.remove();
    });

    const _circles = cirleData.flat().map((floorEntity: any) => {
      const newCircle: L.Circle = L.circle(floorEntity?.latlng, {
        color: floorEntity.isBooked ? redBorderColor : greenBorderColor,
        fillColor: floorEntity.isBooked ? redFillColor : greenFillColor,
        fillOpacity: 0.9,
        radius: floorEntity?.radius,
        className: floorEntity.isBooked ? "room-booked" : "",
        attribution:
          floorEntity?.id + "-innerGoGetIDSplitter-" + floorEntity?.name,
      });

      return newCircle;
    });

    const _polygonal = polygonalData.flat().map((floorEntity: any) => {
      let newPolygon: L.Polygon | L.Rectangle;

      if (floorEntity.latlng[0]?.type === "polygon") {
        newPolygon = L.polygon(floorEntity?.latlng, {
          color: floorEntity.isBooked ? redBorderColor : greenBorderColor,
          fillColor: floorEntity.isBooked ? redFillColor : greenFillColor,
          fillOpacity: 0.9,
          className: floorEntity.isBooked ? "room-booked" : "",
          attribution:
            floorEntity?.id + "-innerGoGetIDSplitter-" + floorEntity?.name,
        });
      } else {
        newPolygon = L.rectangle(floorEntity?.latlng, {
          color: floorEntity.isBooked ? redBorderColor : greenBorderColor,
          fillColor: floorEntity.isBooked ? redFillColor : greenFillColor,
          fillOpacity: 0.9,
          className: floorEntity.isBooked ? "room-booked" : "",
          attribution:
            floorEntity?.id + "-innerGoGetIDSplitter-" + floorEntity?.name,
        });
      }

      return newPolygon;
    });

    circleCacheRef.current = _circles;
    polygonCacheRef.current = _polygonal;

    return { _circles, _polygonal };
  }, [rooms, desks]);

  const markers = useMemo<MarkerType[]>(() => {
    markersCacheRef.current.forEach((cachedMarker) => {
      cachedMarker.marker.remove();
    });

    const _markers = markerData.flat().map((floorEntity: any) => {
      return {
        id: floorEntity.id,
        marker: L.marker(floorEntity?.latlng, {
          icon: getFloorEntityLeafletIcon(floorEntity, "bookings"),
          draggable: false,
          attribution: floorEntity?.id,
          alt: "Marker",
        }),
        isBooked: floorEntity.isBooked,
        isAssigned: floorEntity.isAssigned,
        isBlocked: floorEntity.isBlocked,
        busySlot: floorEntity.busySlots,
        type: floorEntity.type,
        location: floorEntity?.location,
        name: floorEntity.name,
        tags: floorEntity.tags,
        username: floorEntity?.username,
        attachedUserPopup: false,
        attendeeImage: "",
      };
    });

    // we add extra markers for when a user from Find people matches a
    // reserved desk
    if (searchedAttendees.length > 0 && desks.length > 0) {
      searchedAttendees.map((attendee) =>
        desks
          .filter(
            (desk) => desk.isBooked && desk.organizer?.email === attendee.email
          )
          .map((deskMarker: FloorChildrenType) => {
            return _markers.push({
              busySlot: deskMarker.busySlots,
              id: deskMarker.id,
              isBooked: deskMarker.isBooked,
              isAssigned: deskMarker.isAssigned,
              isBlocked: deskMarker.isBlocked,
              location: "",
              name: deskMarker.name,
              tags: deskMarker.tags,
              type: "Desk",
              username: deskMarker.organizer?.displayName,
              attachedUserPopup: true,
              attendeeImage: attendee?.avatar?.url || "",
              marker: L.marker(
                [
                  deskMarker?.marker?.latitude || 0,
                  deskMarker?.marker?.longitude || 0,
                ],
                {
                  icon: new L.DivIcon({
                    html: renderToStaticMarkup(
                      <UserLeafletMarker
                        key={deskMarker.id}
                        attendee={attendee}
                        showReservationInfo={false}
                      />
                    ),
                    iconSize: [0, 0],
                    iconAnchor: [0, 0],
                  }),
                  draggable: false,
                  attribution: deskMarker.id,
                  alt: "User marker",
                }
              ),
            });
          })
      );
    }

    markersCacheRef.current = _markers;

    return _markers;
  }, [rooms, desks, searchedAttendees]);

  const openMarkerOnInitialization = (id: string) => {
    let clickedMarker = markers.filter(
      ({ marker }) => marker.options.attribution === id
    );

    if (clickedMarker.length >= 1) {
      clickedMarker[0].marker.openPopup();
    }
  };

  return { markers, shapes, openMarkerOnInitialization };
};
