import React from "react";
import cs from "classnames";
import { ReservationListActions } from "../../../Bookings/Reservations/ReservationList/ReservationListActions";
import { DeskBookingEvent } from "../../../../api/grpc/desk/desk";
import { Event as RoomEvent } from "../../../../api/grpc/desk/ggevent/ggevent";
import { Event as DeskEvent } from "../../../../api/grpc/booking/ggevent/ggevent";
import { dateToHoursAndMinutes } from "../../../Bookings/lib/dateInputConvert";
import { useTimeZoneContext } from "../../../../lib/context/TimeZone/TimeZoneContext";
import { useAuthContext } from "../../../../lib/context/Auth/AuthContext";
import { BookingTypes } from "../../../Bookings/BookingsSearch/BookingsSearch";
import { RolePermissions } from "../../../../api/grpc/account/account";

interface Props {
  event: RoomEvent | DeskEvent;
  type: BookingTypes;
  workplaceID: string;
  workspaceName: string;
  refetchDesk?: () => void;
}

export const ReservationEvent = ({
  event,
  type,
  workplaceID,
  workspaceName,
  refetchDesk,
}: Props) => {
  const { user } = useAuthContext();

  const { timeZone } = useTimeZoneContext();

  const organizer = event.attendees.find(
    (attendee) =>
      attendee.email !== user?.email && attendee.mode1Organizer === true
  );

  const isAuthedUsersEvent = event.organizer?.email === user?.email;

  const defineStatusName = () => {
    if (event.isCheckOut) {
      return "Checked out";
    }

    if (new Date() > new Date(event.startTime) && !event.allDay) {
      return "Ongoing";
    }

    return "";
  };

  const defineEventName = () => {
    if (type === "desk") {
      if (isAuthedUsersEvent) {
        return event.organizer?.displayName + " (You)";
      }

      if (
        !user?.permissions.includes(
          RolePermissions.RESERVATIONS_MEMBER_STYLE_VIEW
        )
      ) {
        return event?.organizer?.displayName;
      }

      return event?.organizer?.allowToFind
        ? event.organizer?.displayName
        : "Reserved";
    }

    //room
    return organizer ? organizer.displayName : "Event name";
  };

  return (
    <div className="ReservationEvent mt-4 default-box">
      <div className="flex-a-center">
        <span>
          {event.allDay === true
            ? "All day"
            : dateToHoursAndMinutes(new Date(event.startTime), timeZone) +
              " - " +
              dateToHoursAndMinutes(new Date(event.endTime), timeZone)}
        </span>

        <span
          className={cs("ReservationEvent__status", {
            "ReservationEvent__status--checkOut": event.isCheckOut,
            "ReservationEvent__status--ongoing":
              new Date() > new Date(event.startTime) && !event.allDay,
            "ReservationEvent__status--decline": false,
            "ReservationEvent__status--await": false,
          })}
        >
          {defineStatusName()}
        </span>
      </div>
      <h6 className="mt-2 overflow-text">{defineEventName()}</h6>

      {type === "room" && event.title.length > 0 && (
        <span className="text-secondary d-block overflow-text">
          ({event.title})
        </span>
      )}

      {event && (
        <ReservationListActions
          type={type}
          workplaceId={workplaceID}
          event={event}
          startTime={event.startTime}
          endTime={event.endTime}
          name={workspaceName}
          locationPath={[]}
          checkInStatus={event.eventCheckInStatus}
          // qrCodeRequired={qrCodeRequired}
          isAuthedUsersEvent={isAuthedUsersEvent}
          allowDeleteOption={true}
          isAttendee={event.organizer?.mode1Organizer !== true && !!organizer}
          refetchDesk={refetchDesk}
        />
      )}
    </div>
  );
};
