// @generated by protobuf-ts 2.9.4 with parameter long_type_number,generate_dependencies
// @generated from protobuf file "desk.proto" (syntax proto3)
// tslint:disable
import { Empty } from "./google/protobuf/empty";
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { EventCheckInStatus } from "./ggevent/ggevent";
import { BusyTimeSlots } from "./ggevent/ggevent";
import { QRCodeRequiredStatus } from "./ggevent/ggevent";
import { Event } from "./ggevent/ggevent";
/**
 * @generated from protobuf message CreateDeskBookingRequest
 */
export interface CreateDeskBookingRequest {
    /**
     * @generated from protobuf field: ggevent.Event event = 1;
     */
    event?: Event;
    /**
     * @generated from protobuf field: string customerID = 2;
     */
    customerID: string;
    /**
     * @generated from protobuf field: string deskID = 3;
     */
    deskID: string;
    /**
     * @generated from protobuf field: string accountID = 4;
     */
    accountID: string;
}
/**
 * @generated from protobuf message UpdateDeskBookingRequest
 */
export interface UpdateDeskBookingRequest {
    /**
     * @generated from protobuf field: ggevent.Event event = 1;
     */
    event?: Event;
    /**
     * @generated from protobuf field: string customerID = 2;
     */
    customerID: string;
    /**
     * @generated from protobuf field: string deskID = 3;
     */
    deskID: string;
    /**
     * @generated from protobuf field: string accountID = 4;
     */
    accountID: string;
    /**
     * @generated from protobuf field: string eventID = 5;
     */
    eventID: string;
}
/**
 * @generated from protobuf message DeskBookingEvent
 */
export interface DeskBookingEvent {
    /**
     * @generated from protobuf field: ggevent.Event event = 1;
     */
    event?: Event;
    /**
     * @generated from protobuf field: string customerID = 2;
     */
    customerID: string;
    /**
     * @generated from protobuf field: string deskID = 3;
     */
    deskID: string;
    /**
     * @generated from protobuf field: string accountID = 4;
     */
    accountID: string;
    /**
     * @generated from protobuf field: string locationID = 5;
     */
    locationID: string;
    /**
     * @generated from protobuf field: ggevent.QRCodeRequiredStatus qrCodeRequired = 6;
     */
    qrCodeRequired: QRCodeRequiredStatus;
}
/**
 * @generated from protobuf message DeskBookingRequest
 */
export interface DeskBookingRequest {
    /**
     * @generated from protobuf field: string customerID = 1;
     */
    customerID: string;
    /**
     * @generated from protobuf field: string deskID = 2;
     */
    deskID: string;
}
/**
 * @generated from protobuf message ListDeskBookingEventResponse
 */
export interface ListDeskBookingEventResponse {
    /**
     * @generated from protobuf field: repeated ggevent.Event event = 1;
     */
    event: Event[];
    /**
     * @generated from protobuf field: string customerID = 2;
     */
    customerID: string;
    /**
     * @generated from protobuf field: string deskID = 3;
     */
    deskID: string;
}
/**
 * @generated from protobuf message DeleteDeskBookingRequest
 */
export interface DeleteDeskBookingRequest {
    /**
     * @generated from protobuf field: string customerID = 1;
     */
    customerID: string;
    /**
     * @generated from protobuf field: string deskID = 2;
     */
    deskID: string;
    /**
     * @generated from protobuf field: string accountID = 3;
     */
    accountID: string;
    /**
     * @generated from protobuf field: string eventID = 4;
     */
    eventID: string;
}
/**
 * @generated from protobuf message RetrieveDeskBookingRequest
 */
export interface RetrieveDeskBookingRequest {
    /**
     * @generated from protobuf field: string accountID = 1;
     */
    accountID: string;
    /**
     * @generated from protobuf field: string eventID = 2;
     */
    eventID: string;
}
/**
 * @generated from protobuf message MatchingDeskRequest
 */
export interface MatchingDeskRequest {
    /**
     * @generated from protobuf field: string startTime = 1;
     */
    startTime: string;
    /**
     * @generated from protobuf field: string endTime = 2;
     */
    endTime: string;
    /**
     * @generated from protobuf field: string locationID = 3;
     */
    locationID: string;
    /**
     * @generated from protobuf field: string customerID = 4;
     */
    customerID: string;
    /**
     * @generated from protobuf field: string accountID = 6;
     */
    accountID: string;
    /**
     * @generated from protobuf field: repeated string tagsFilter = 7;
     */
    tagsFilter: string[];
    /**
     * @generated from protobuf field: string timeZone = 8;
     */
    timeZone: string;
    /**
     * @generated from protobuf field: int32 page = 9;
     */
    page: number;
}
/**
 * @generated from protobuf message MapMarker
 */
export interface MapMarker {
    /**
     * @generated from protobuf field: int32 latitude = 1;
     */
    latitude: number;
    /**
     * @generated from protobuf field: int32 longitude = 2;
     */
    longitude: number;
}
/**
 * @generated from protobuf message DeskLocationItem
 */
export interface DeskLocationItem {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string name = 2;
     */
    name: string;
    /**
     * @generated from protobuf field: string type = 3;
     */
    type: string;
    /**
     * @generated from protobuf field: int32 index = 4;
     */
    index: number;
}
/**
 * @generated from protobuf message MatchingDesk
 */
export interface MatchingDesk {
    /**
     * @generated from protobuf field: string deskID = 1;
     */
    deskID: string;
    /**
     * @generated from protobuf field: string deskName = 2;
     */
    deskName: string;
    /**
     * @generated from protobuf field: repeated ggevent.Event event = 3;
     */
    event: Event[];
    /**
     * @generated from protobuf field: string locationID = 4;
     */
    locationID: string;
    /**
     * @generated from protobuf field: repeated string tagsFilter = 5;
     */
    tagsFilter: string[];
    /**
     * @generated from protobuf field: ggevent.QRCodeRequiredStatus qrCodeRequired = 6;
     */
    qrCodeRequired: QRCodeRequiredStatus;
    /**
     * @generated from protobuf field: MapMarker marker = 7;
     */
    marker?: MapMarker;
    /**
     * @generated from protobuf field: repeated DeskLocationItem locationPath = 8;
     */
    locationPath: DeskLocationItem[];
    /**
     * @generated from protobuf field: string description = 9;
     */
    description: string;
    /**
     * start time for next booked event
     *
     * @generated from protobuf field: string upcomingEvent = 10;
     */
    upcomingEvent: string;
    /**
     * @generated from protobuf field: bool isBlocked = 11;
     */
    isBlocked: boolean;
}
/**
 * @generated from protobuf message ListDesksResponse
 */
export interface ListDesksResponse {
    /**
     * @generated from protobuf field: repeated MatchingDesk desks = 1;
     */
    desks: MatchingDesk[];
    /**
     * @generated from protobuf field: string customerID = 2;
     */
    customerID: string;
    /**
     * @generated from protobuf field: string accountID = 3;
     */
    accountID: string;
    /**
     * @generated from protobuf field: int64 next_page = 4;
     */
    nextPage: number;
}
/**
 * @generated from protobuf message PersonBookingRequest
 */
export interface PersonBookingRequest {
    /**
     * @generated from protobuf field: string startTime = 1;
     */
    startTime: string;
    /**
     * @generated from protobuf field: string endTime = 2;
     */
    endTime: string;
    /**
     * @generated from protobuf field: string customerID = 4;
     */
    customerID: string;
    /**
     * @generated from protobuf field: string accountID = 5;
     */
    accountID: string;
    /**
     * @generated from protobuf field: repeated string tagsFilter = 6;
     */
    tagsFilter: string[];
}
/**
 * @generated from protobuf message ListDeskBookingRequest
 */
export interface ListDeskBookingRequest {
    /**
     * @generated from protobuf field: string customerID = 1;
     */
    customerID: string;
    /**
     * @generated from protobuf field: string accountID = 2;
     */
    accountID: string;
}
/**
 * @generated from protobuf message DeskCheckInRequest
 */
export interface DeskCheckInRequest {
    /**
     * @generated from protobuf field: string eventID = 1;
     */
    eventID: string;
    /**
     * @generated from protobuf field: string accountID = 2;
     */
    accountID: string;
    /**
     * @generated from protobuf field: string customerID = 3;
     */
    customerID: string;
}
/**
 * @generated from protobuf message DeskCheckInResponse
 */
export interface DeskCheckInResponse {
    /**
     * @generated from protobuf field: DeskCheckInResponseStatus status = 1;
     */
    status: DeskCheckInResponseStatus;
}
/**
 * @generated from protobuf message DeskCheckOutRequest
 */
export interface DeskCheckOutRequest {
    /**
     * @generated from protobuf field: string eventID = 1;
     */
    eventID: string;
    /**
     * @generated from protobuf field: string customerID = 2;
     */
    customerID: string;
    /**
     * @generated from protobuf field: bool isSystemCheckOut = 3;
     */
    isSystemCheckOut: boolean;
}
/**
 * @generated from protobuf message DeskScheduleRequest
 */
export interface DeskScheduleRequest {
    /**
     * @generated from protobuf field: string customer_id = 1;
     */
    customerId: string;
    /**
     * @generated from protobuf field: string deskID = 2;
     */
    deskID: string;
    /**
     * @generated from protobuf field: string startTime = 4;
     */
    startTime: string;
    /**
     * @generated from protobuf field: string endTime = 5;
     */
    endTime: string;
    /**
     * @generated from protobuf field: string timeZone = 6;
     */
    timeZone: string;
    /**
     * @generated from protobuf field: string accountID = 7;
     */
    accountID: string;
}
/**
 * @generated from protobuf message DeskScheduleResponse
 */
export interface DeskScheduleResponse {
    /**
     * @generated from protobuf field: repeated ggevent.BusyTimeSlots timeSlots = 1;
     */
    timeSlots: BusyTimeSlots[];
}
/**
 * @generated from protobuf message MultipleDeskScheduleRequest
 */
export interface MultipleDeskScheduleRequest {
    /**
     * @generated from protobuf field: string customer_id = 1;
     */
    customerId: string;
    /**
     * @generated from protobuf field: repeated string desksID = 2;
     */
    desksID: string[];
    /**
     * @generated from protobuf field: string startTime = 4;
     */
    startTime: string;
    /**
     * @generated from protobuf field: string endTime = 5;
     */
    endTime: string;
    /**
     * @generated from protobuf field: string timeZome = 6;
     */
    timeZome: string;
}
/**
 * @generated from protobuf message MultipleDeskTimeSlot
 */
export interface MultipleDeskTimeSlot {
    /**
     * @generated from protobuf field: string deskID = 1;
     */
    deskID: string;
    /**
     * @generated from protobuf field: repeated ggevent.BusyTimeSlots timeSlots = 2;
     */
    timeSlots: BusyTimeSlots[];
    /**
     * @generated from protobuf field: bool blocked = 3;
     */
    blocked: boolean;
    /**
     * @generated from protobuf field: bool assigned = 4;
     */
    assigned: boolean;
}
/**
 * @generated from protobuf message MultipleDeskScheduleResponse
 */
export interface MultipleDeskScheduleResponse {
    /**
     * @generated from protobuf field: repeated MultipleDeskTimeSlot timeSlots = 1;
     */
    timeSlots: MultipleDeskTimeSlot[];
}
/**
 * @generated from protobuf message DeskCheckInDefferRequest
 */
export interface DeskCheckInDefferRequest {
    /**
     * @generated from protobuf field: string eventID = 1;
     */
    eventID: string;
    /**
     * @generated from protobuf field: string customerID = 2;
     */
    customerID: string;
    /**
     * @generated from protobuf field: ggevent.EventCheckInStatus eventStatus = 3;
     */
    eventStatus: EventCheckInStatus;
}
/**
 * @generated from protobuf message ListDesksBookingRequest
 */
export interface ListDesksBookingRequest {
    /**
     * @generated from protobuf field: string startTime = 1;
     */
    startTime: string;
    /**
     * @generated from protobuf field: string endTime = 2;
     */
    endTime: string;
    /**
     * @generated from protobuf field: string customerID = 4;
     */
    customerID: string;
    /**
     * @generated from protobuf field: repeated string desksId = 5;
     */
    desksId: string[];
}
/**
 * @generated from protobuf message ListDesksBookingObject
 */
export interface ListDesksBookingObject {
    /**
     * @generated from protobuf field: string deskId = 1;
     */
    deskId: string;
    /**
     * @generated from protobuf field: string deskName = 2;
     */
    deskName: string;
    /**
     * @generated from protobuf field: repeated string deskTags = 3;
     */
    deskTags: string[];
    /**
     * @generated from protobuf field: repeated DeskLocationItem deskLocationPath = 4;
     */
    deskLocationPath: DeskLocationItem[];
    /**
     * @generated from protobuf field: repeated DeskBookingEvent events = 5;
     */
    events: DeskBookingEvent[];
}
/**
 * @generated from protobuf message ListDesksBookingResponse
 */
export interface ListDesksBookingResponse {
    /**
     * @generated from protobuf field: repeated ListDesksBookingObject data = 1;
     */
    data: ListDesksBookingObject[];
}
/**
 * @generated from protobuf enum DeskCheckInResponseStatus
 */
export enum DeskCheckInResponseStatus {
    /**
     * @generated from protobuf enum value: DESK_CHECKIN_RESPONSE_NONE = 0;
     */
    DESK_CHECKIN_RESPONSE_NONE = 0,
    /**
     * @generated from protobuf enum value: DESK_CHECKIN_RESPONSE_NOT_REQUIRED = 1;
     */
    DESK_CHECKIN_RESPONSE_NOT_REQUIRED = 1,
    /**
     * @generated from protobuf enum value: DESK_CHECKIN_RESPONSE_SUCCESS = 2;
     */
    DESK_CHECKIN_RESPONSE_SUCCESS = 2,
    /**
     * @generated from protobuf enum value: DESK_CHECKIN_RESPONSE_NOT_OPENED = 3;
     */
    DESK_CHECKIN_RESPONSE_NOT_OPENED = 3,
    /**
     * @generated from protobuf enum value: DESK_CHECKIN_RESPONSE_CLOSED = 4;
     */
    DESK_CHECKIN_RESPONSE_CLOSED = 4,
    /**
     * @generated from protobuf enum value: DESK_CHECKIN_CHECK_OUT = 5;
     */
    DESK_CHECKIN_CHECK_OUT = 5,
    /**
     * @generated from protobuf enum value: DESK_CHECKIN_RESPONSE_OPEN = 6;
     */
    DESK_CHECKIN_RESPONSE_OPEN = 6
}
// @generated message type with reflection information, may provide speed optimized methods
class CreateDeskBookingRequest$Type extends MessageType<CreateDeskBookingRequest> {
    constructor() {
        super("CreateDeskBookingRequest", [
            { no: 1, name: "event", kind: "message", T: () => Event },
            { no: 2, name: "customerID", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "deskID", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "accountID", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<CreateDeskBookingRequest>): CreateDeskBookingRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.customerID = "";
        message.deskID = "";
        message.accountID = "";
        if (value !== undefined)
            reflectionMergePartial<CreateDeskBookingRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CreateDeskBookingRequest): CreateDeskBookingRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* ggevent.Event event */ 1:
                    message.event = Event.internalBinaryRead(reader, reader.uint32(), options, message.event);
                    break;
                case /* string customerID */ 2:
                    message.customerID = reader.string();
                    break;
                case /* string deskID */ 3:
                    message.deskID = reader.string();
                    break;
                case /* string accountID */ 4:
                    message.accountID = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CreateDeskBookingRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* ggevent.Event event = 1; */
        if (message.event)
            Event.internalBinaryWrite(message.event, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* string customerID = 2; */
        if (message.customerID !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.customerID);
        /* string deskID = 3; */
        if (message.deskID !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.deskID);
        /* string accountID = 4; */
        if (message.accountID !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.accountID);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message CreateDeskBookingRequest
 */
export const CreateDeskBookingRequest = new CreateDeskBookingRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateDeskBookingRequest$Type extends MessageType<UpdateDeskBookingRequest> {
    constructor() {
        super("UpdateDeskBookingRequest", [
            { no: 1, name: "event", kind: "message", T: () => Event },
            { no: 2, name: "customerID", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "deskID", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "accountID", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "eventID", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<UpdateDeskBookingRequest>): UpdateDeskBookingRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.customerID = "";
        message.deskID = "";
        message.accountID = "";
        message.eventID = "";
        if (value !== undefined)
            reflectionMergePartial<UpdateDeskBookingRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateDeskBookingRequest): UpdateDeskBookingRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* ggevent.Event event */ 1:
                    message.event = Event.internalBinaryRead(reader, reader.uint32(), options, message.event);
                    break;
                case /* string customerID */ 2:
                    message.customerID = reader.string();
                    break;
                case /* string deskID */ 3:
                    message.deskID = reader.string();
                    break;
                case /* string accountID */ 4:
                    message.accountID = reader.string();
                    break;
                case /* string eventID */ 5:
                    message.eventID = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UpdateDeskBookingRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* ggevent.Event event = 1; */
        if (message.event)
            Event.internalBinaryWrite(message.event, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* string customerID = 2; */
        if (message.customerID !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.customerID);
        /* string deskID = 3; */
        if (message.deskID !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.deskID);
        /* string accountID = 4; */
        if (message.accountID !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.accountID);
        /* string eventID = 5; */
        if (message.eventID !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.eventID);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message UpdateDeskBookingRequest
 */
export const UpdateDeskBookingRequest = new UpdateDeskBookingRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DeskBookingEvent$Type extends MessageType<DeskBookingEvent> {
    constructor() {
        super("DeskBookingEvent", [
            { no: 1, name: "event", kind: "message", T: () => Event },
            { no: 2, name: "customerID", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "deskID", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "accountID", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "locationID", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "qrCodeRequired", kind: "enum", T: () => ["ggevent.QRCodeRequiredStatus", QRCodeRequiredStatus] }
        ]);
    }
    create(value?: PartialMessage<DeskBookingEvent>): DeskBookingEvent {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.customerID = "";
        message.deskID = "";
        message.accountID = "";
        message.locationID = "";
        message.qrCodeRequired = 0;
        if (value !== undefined)
            reflectionMergePartial<DeskBookingEvent>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DeskBookingEvent): DeskBookingEvent {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* ggevent.Event event */ 1:
                    message.event = Event.internalBinaryRead(reader, reader.uint32(), options, message.event);
                    break;
                case /* string customerID */ 2:
                    message.customerID = reader.string();
                    break;
                case /* string deskID */ 3:
                    message.deskID = reader.string();
                    break;
                case /* string accountID */ 4:
                    message.accountID = reader.string();
                    break;
                case /* string locationID */ 5:
                    message.locationID = reader.string();
                    break;
                case /* ggevent.QRCodeRequiredStatus qrCodeRequired */ 6:
                    message.qrCodeRequired = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DeskBookingEvent, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* ggevent.Event event = 1; */
        if (message.event)
            Event.internalBinaryWrite(message.event, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* string customerID = 2; */
        if (message.customerID !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.customerID);
        /* string deskID = 3; */
        if (message.deskID !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.deskID);
        /* string accountID = 4; */
        if (message.accountID !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.accountID);
        /* string locationID = 5; */
        if (message.locationID !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.locationID);
        /* ggevent.QRCodeRequiredStatus qrCodeRequired = 6; */
        if (message.qrCodeRequired !== 0)
            writer.tag(6, WireType.Varint).int32(message.qrCodeRequired);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message DeskBookingEvent
 */
export const DeskBookingEvent = new DeskBookingEvent$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DeskBookingRequest$Type extends MessageType<DeskBookingRequest> {
    constructor() {
        super("DeskBookingRequest", [
            { no: 1, name: "customerID", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "deskID", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<DeskBookingRequest>): DeskBookingRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.customerID = "";
        message.deskID = "";
        if (value !== undefined)
            reflectionMergePartial<DeskBookingRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DeskBookingRequest): DeskBookingRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string customerID */ 1:
                    message.customerID = reader.string();
                    break;
                case /* string deskID */ 2:
                    message.deskID = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DeskBookingRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string customerID = 1; */
        if (message.customerID !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.customerID);
        /* string deskID = 2; */
        if (message.deskID !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.deskID);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message DeskBookingRequest
 */
export const DeskBookingRequest = new DeskBookingRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListDeskBookingEventResponse$Type extends MessageType<ListDeskBookingEventResponse> {
    constructor() {
        super("ListDeskBookingEventResponse", [
            { no: 1, name: "event", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Event },
            { no: 2, name: "customerID", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "deskID", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ListDeskBookingEventResponse>): ListDeskBookingEventResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.event = [];
        message.customerID = "";
        message.deskID = "";
        if (value !== undefined)
            reflectionMergePartial<ListDeskBookingEventResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListDeskBookingEventResponse): ListDeskBookingEventResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated ggevent.Event event */ 1:
                    message.event.push(Event.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* string customerID */ 2:
                    message.customerID = reader.string();
                    break;
                case /* string deskID */ 3:
                    message.deskID = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListDeskBookingEventResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated ggevent.Event event = 1; */
        for (let i = 0; i < message.event.length; i++)
            Event.internalBinaryWrite(message.event[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* string customerID = 2; */
        if (message.customerID !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.customerID);
        /* string deskID = 3; */
        if (message.deskID !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.deskID);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ListDeskBookingEventResponse
 */
export const ListDeskBookingEventResponse = new ListDeskBookingEventResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DeleteDeskBookingRequest$Type extends MessageType<DeleteDeskBookingRequest> {
    constructor() {
        super("DeleteDeskBookingRequest", [
            { no: 1, name: "customerID", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "deskID", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "accountID", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "eventID", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<DeleteDeskBookingRequest>): DeleteDeskBookingRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.customerID = "";
        message.deskID = "";
        message.accountID = "";
        message.eventID = "";
        if (value !== undefined)
            reflectionMergePartial<DeleteDeskBookingRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DeleteDeskBookingRequest): DeleteDeskBookingRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string customerID */ 1:
                    message.customerID = reader.string();
                    break;
                case /* string deskID */ 2:
                    message.deskID = reader.string();
                    break;
                case /* string accountID */ 3:
                    message.accountID = reader.string();
                    break;
                case /* string eventID */ 4:
                    message.eventID = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DeleteDeskBookingRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string customerID = 1; */
        if (message.customerID !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.customerID);
        /* string deskID = 2; */
        if (message.deskID !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.deskID);
        /* string accountID = 3; */
        if (message.accountID !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.accountID);
        /* string eventID = 4; */
        if (message.eventID !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.eventID);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message DeleteDeskBookingRequest
 */
export const DeleteDeskBookingRequest = new DeleteDeskBookingRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RetrieveDeskBookingRequest$Type extends MessageType<RetrieveDeskBookingRequest> {
    constructor() {
        super("RetrieveDeskBookingRequest", [
            { no: 1, name: "accountID", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "eventID", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<RetrieveDeskBookingRequest>): RetrieveDeskBookingRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.accountID = "";
        message.eventID = "";
        if (value !== undefined)
            reflectionMergePartial<RetrieveDeskBookingRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RetrieveDeskBookingRequest): RetrieveDeskBookingRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string accountID */ 1:
                    message.accountID = reader.string();
                    break;
                case /* string eventID */ 2:
                    message.eventID = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: RetrieveDeskBookingRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string accountID = 1; */
        if (message.accountID !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.accountID);
        /* string eventID = 2; */
        if (message.eventID !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.eventID);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message RetrieveDeskBookingRequest
 */
export const RetrieveDeskBookingRequest = new RetrieveDeskBookingRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MatchingDeskRequest$Type extends MessageType<MatchingDeskRequest> {
    constructor() {
        super("MatchingDeskRequest", [
            { no: 1, name: "startTime", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "endTime", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "locationID", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "customerID", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "accountID", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "tagsFilter", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "timeZone", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "page", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<MatchingDeskRequest>): MatchingDeskRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.startTime = "";
        message.endTime = "";
        message.locationID = "";
        message.customerID = "";
        message.accountID = "";
        message.tagsFilter = [];
        message.timeZone = "";
        message.page = 0;
        if (value !== undefined)
            reflectionMergePartial<MatchingDeskRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MatchingDeskRequest): MatchingDeskRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string startTime */ 1:
                    message.startTime = reader.string();
                    break;
                case /* string endTime */ 2:
                    message.endTime = reader.string();
                    break;
                case /* string locationID */ 3:
                    message.locationID = reader.string();
                    break;
                case /* string customerID */ 4:
                    message.customerID = reader.string();
                    break;
                case /* string accountID */ 6:
                    message.accountID = reader.string();
                    break;
                case /* repeated string tagsFilter */ 7:
                    message.tagsFilter.push(reader.string());
                    break;
                case /* string timeZone */ 8:
                    message.timeZone = reader.string();
                    break;
                case /* int32 page */ 9:
                    message.page = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: MatchingDeskRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string startTime = 1; */
        if (message.startTime !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.startTime);
        /* string endTime = 2; */
        if (message.endTime !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.endTime);
        /* string locationID = 3; */
        if (message.locationID !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.locationID);
        /* string customerID = 4; */
        if (message.customerID !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.customerID);
        /* string accountID = 6; */
        if (message.accountID !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.accountID);
        /* repeated string tagsFilter = 7; */
        for (let i = 0; i < message.tagsFilter.length; i++)
            writer.tag(7, WireType.LengthDelimited).string(message.tagsFilter[i]);
        /* string timeZone = 8; */
        if (message.timeZone !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.timeZone);
        /* int32 page = 9; */
        if (message.page !== 0)
            writer.tag(9, WireType.Varint).int32(message.page);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message MatchingDeskRequest
 */
export const MatchingDeskRequest = new MatchingDeskRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MapMarker$Type extends MessageType<MapMarker> {
    constructor() {
        super("MapMarker", [
            { no: 1, name: "latitude", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "longitude", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<MapMarker>): MapMarker {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.latitude = 0;
        message.longitude = 0;
        if (value !== undefined)
            reflectionMergePartial<MapMarker>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MapMarker): MapMarker {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 latitude */ 1:
                    message.latitude = reader.int32();
                    break;
                case /* int32 longitude */ 2:
                    message.longitude = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: MapMarker, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 latitude = 1; */
        if (message.latitude !== 0)
            writer.tag(1, WireType.Varint).int32(message.latitude);
        /* int32 longitude = 2; */
        if (message.longitude !== 0)
            writer.tag(2, WireType.Varint).int32(message.longitude);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message MapMarker
 */
export const MapMarker = new MapMarker$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DeskLocationItem$Type extends MessageType<DeskLocationItem> {
    constructor() {
        super("DeskLocationItem", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "type", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "index", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<DeskLocationItem>): DeskLocationItem {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = "";
        message.name = "";
        message.type = "";
        message.index = 0;
        if (value !== undefined)
            reflectionMergePartial<DeskLocationItem>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DeskLocationItem): DeskLocationItem {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                case /* string type */ 3:
                    message.type = reader.string();
                    break;
                case /* int32 index */ 4:
                    message.index = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DeskLocationItem, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        /* string type = 3; */
        if (message.type !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.type);
        /* int32 index = 4; */
        if (message.index !== 0)
            writer.tag(4, WireType.Varint).int32(message.index);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message DeskLocationItem
 */
export const DeskLocationItem = new DeskLocationItem$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MatchingDesk$Type extends MessageType<MatchingDesk> {
    constructor() {
        super("MatchingDesk", [
            { no: 1, name: "deskID", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "deskName", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "event", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Event },
            { no: 4, name: "locationID", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "tagsFilter", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "qrCodeRequired", kind: "enum", T: () => ["ggevent.QRCodeRequiredStatus", QRCodeRequiredStatus] },
            { no: 7, name: "marker", kind: "message", T: () => MapMarker },
            { no: 8, name: "locationPath", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => DeskLocationItem },
            { no: 9, name: "description", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 10, name: "upcomingEvent", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 11, name: "isBlocked", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<MatchingDesk>): MatchingDesk {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.deskID = "";
        message.deskName = "";
        message.event = [];
        message.locationID = "";
        message.tagsFilter = [];
        message.qrCodeRequired = 0;
        message.locationPath = [];
        message.description = "";
        message.upcomingEvent = "";
        message.isBlocked = false;
        if (value !== undefined)
            reflectionMergePartial<MatchingDesk>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MatchingDesk): MatchingDesk {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string deskID */ 1:
                    message.deskID = reader.string();
                    break;
                case /* string deskName */ 2:
                    message.deskName = reader.string();
                    break;
                case /* repeated ggevent.Event event */ 3:
                    message.event.push(Event.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* string locationID */ 4:
                    message.locationID = reader.string();
                    break;
                case /* repeated string tagsFilter */ 5:
                    message.tagsFilter.push(reader.string());
                    break;
                case /* ggevent.QRCodeRequiredStatus qrCodeRequired */ 6:
                    message.qrCodeRequired = reader.int32();
                    break;
                case /* MapMarker marker */ 7:
                    message.marker = MapMarker.internalBinaryRead(reader, reader.uint32(), options, message.marker);
                    break;
                case /* repeated DeskLocationItem locationPath */ 8:
                    message.locationPath.push(DeskLocationItem.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* string description */ 9:
                    message.description = reader.string();
                    break;
                case /* string upcomingEvent */ 10:
                    message.upcomingEvent = reader.string();
                    break;
                case /* bool isBlocked */ 11:
                    message.isBlocked = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: MatchingDesk, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string deskID = 1; */
        if (message.deskID !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.deskID);
        /* string deskName = 2; */
        if (message.deskName !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.deskName);
        /* repeated ggevent.Event event = 3; */
        for (let i = 0; i < message.event.length; i++)
            Event.internalBinaryWrite(message.event[i], writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* string locationID = 4; */
        if (message.locationID !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.locationID);
        /* repeated string tagsFilter = 5; */
        for (let i = 0; i < message.tagsFilter.length; i++)
            writer.tag(5, WireType.LengthDelimited).string(message.tagsFilter[i]);
        /* ggevent.QRCodeRequiredStatus qrCodeRequired = 6; */
        if (message.qrCodeRequired !== 0)
            writer.tag(6, WireType.Varint).int32(message.qrCodeRequired);
        /* MapMarker marker = 7; */
        if (message.marker)
            MapMarker.internalBinaryWrite(message.marker, writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        /* repeated DeskLocationItem locationPath = 8; */
        for (let i = 0; i < message.locationPath.length; i++)
            DeskLocationItem.internalBinaryWrite(message.locationPath[i], writer.tag(8, WireType.LengthDelimited).fork(), options).join();
        /* string description = 9; */
        if (message.description !== "")
            writer.tag(9, WireType.LengthDelimited).string(message.description);
        /* string upcomingEvent = 10; */
        if (message.upcomingEvent !== "")
            writer.tag(10, WireType.LengthDelimited).string(message.upcomingEvent);
        /* bool isBlocked = 11; */
        if (message.isBlocked !== false)
            writer.tag(11, WireType.Varint).bool(message.isBlocked);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message MatchingDesk
 */
export const MatchingDesk = new MatchingDesk$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListDesksResponse$Type extends MessageType<ListDesksResponse> {
    constructor() {
        super("ListDesksResponse", [
            { no: 1, name: "desks", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => MatchingDesk },
            { no: 2, name: "customerID", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "accountID", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "next_page", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<ListDesksResponse>): ListDesksResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.desks = [];
        message.customerID = "";
        message.accountID = "";
        message.nextPage = 0;
        if (value !== undefined)
            reflectionMergePartial<ListDesksResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListDesksResponse): ListDesksResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated MatchingDesk desks */ 1:
                    message.desks.push(MatchingDesk.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* string customerID */ 2:
                    message.customerID = reader.string();
                    break;
                case /* string accountID */ 3:
                    message.accountID = reader.string();
                    break;
                case /* int64 next_page */ 4:
                    message.nextPage = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListDesksResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated MatchingDesk desks = 1; */
        for (let i = 0; i < message.desks.length; i++)
            MatchingDesk.internalBinaryWrite(message.desks[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* string customerID = 2; */
        if (message.customerID !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.customerID);
        /* string accountID = 3; */
        if (message.accountID !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.accountID);
        /* int64 next_page = 4; */
        if (message.nextPage !== 0)
            writer.tag(4, WireType.Varint).int64(message.nextPage);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ListDesksResponse
 */
export const ListDesksResponse = new ListDesksResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PersonBookingRequest$Type extends MessageType<PersonBookingRequest> {
    constructor() {
        super("PersonBookingRequest", [
            { no: 1, name: "startTime", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "endTime", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "customerID", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "accountID", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "tagsFilter", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<PersonBookingRequest>): PersonBookingRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.startTime = "";
        message.endTime = "";
        message.customerID = "";
        message.accountID = "";
        message.tagsFilter = [];
        if (value !== undefined)
            reflectionMergePartial<PersonBookingRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PersonBookingRequest): PersonBookingRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string startTime */ 1:
                    message.startTime = reader.string();
                    break;
                case /* string endTime */ 2:
                    message.endTime = reader.string();
                    break;
                case /* string customerID */ 4:
                    message.customerID = reader.string();
                    break;
                case /* string accountID */ 5:
                    message.accountID = reader.string();
                    break;
                case /* repeated string tagsFilter */ 6:
                    message.tagsFilter.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PersonBookingRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string startTime = 1; */
        if (message.startTime !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.startTime);
        /* string endTime = 2; */
        if (message.endTime !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.endTime);
        /* string customerID = 4; */
        if (message.customerID !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.customerID);
        /* string accountID = 5; */
        if (message.accountID !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.accountID);
        /* repeated string tagsFilter = 6; */
        for (let i = 0; i < message.tagsFilter.length; i++)
            writer.tag(6, WireType.LengthDelimited).string(message.tagsFilter[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message PersonBookingRequest
 */
export const PersonBookingRequest = new PersonBookingRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListDeskBookingRequest$Type extends MessageType<ListDeskBookingRequest> {
    constructor() {
        super("ListDeskBookingRequest", [
            { no: 1, name: "customerID", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "accountID", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ListDeskBookingRequest>): ListDeskBookingRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.customerID = "";
        message.accountID = "";
        if (value !== undefined)
            reflectionMergePartial<ListDeskBookingRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListDeskBookingRequest): ListDeskBookingRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string customerID */ 1:
                    message.customerID = reader.string();
                    break;
                case /* string accountID */ 2:
                    message.accountID = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListDeskBookingRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string customerID = 1; */
        if (message.customerID !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.customerID);
        /* string accountID = 2; */
        if (message.accountID !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.accountID);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ListDeskBookingRequest
 */
export const ListDeskBookingRequest = new ListDeskBookingRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DeskCheckInRequest$Type extends MessageType<DeskCheckInRequest> {
    constructor() {
        super("DeskCheckInRequest", [
            { no: 1, name: "eventID", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "accountID", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "customerID", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<DeskCheckInRequest>): DeskCheckInRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.eventID = "";
        message.accountID = "";
        message.customerID = "";
        if (value !== undefined)
            reflectionMergePartial<DeskCheckInRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DeskCheckInRequest): DeskCheckInRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string eventID */ 1:
                    message.eventID = reader.string();
                    break;
                case /* string accountID */ 2:
                    message.accountID = reader.string();
                    break;
                case /* string customerID */ 3:
                    message.customerID = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DeskCheckInRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string eventID = 1; */
        if (message.eventID !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.eventID);
        /* string accountID = 2; */
        if (message.accountID !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.accountID);
        /* string customerID = 3; */
        if (message.customerID !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.customerID);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message DeskCheckInRequest
 */
export const DeskCheckInRequest = new DeskCheckInRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DeskCheckInResponse$Type extends MessageType<DeskCheckInResponse> {
    constructor() {
        super("DeskCheckInResponse", [
            { no: 1, name: "status", kind: "enum", T: () => ["DeskCheckInResponseStatus", DeskCheckInResponseStatus] }
        ]);
    }
    create(value?: PartialMessage<DeskCheckInResponse>): DeskCheckInResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.status = 0;
        if (value !== undefined)
            reflectionMergePartial<DeskCheckInResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DeskCheckInResponse): DeskCheckInResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* DeskCheckInResponseStatus status */ 1:
                    message.status = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DeskCheckInResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* DeskCheckInResponseStatus status = 1; */
        if (message.status !== 0)
            writer.tag(1, WireType.Varint).int32(message.status);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message DeskCheckInResponse
 */
export const DeskCheckInResponse = new DeskCheckInResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DeskCheckOutRequest$Type extends MessageType<DeskCheckOutRequest> {
    constructor() {
        super("DeskCheckOutRequest", [
            { no: 1, name: "eventID", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "customerID", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "isSystemCheckOut", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<DeskCheckOutRequest>): DeskCheckOutRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.eventID = "";
        message.customerID = "";
        message.isSystemCheckOut = false;
        if (value !== undefined)
            reflectionMergePartial<DeskCheckOutRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DeskCheckOutRequest): DeskCheckOutRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string eventID */ 1:
                    message.eventID = reader.string();
                    break;
                case /* string customerID */ 2:
                    message.customerID = reader.string();
                    break;
                case /* bool isSystemCheckOut */ 3:
                    message.isSystemCheckOut = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DeskCheckOutRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string eventID = 1; */
        if (message.eventID !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.eventID);
        /* string customerID = 2; */
        if (message.customerID !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.customerID);
        /* bool isSystemCheckOut = 3; */
        if (message.isSystemCheckOut !== false)
            writer.tag(3, WireType.Varint).bool(message.isSystemCheckOut);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message DeskCheckOutRequest
 */
export const DeskCheckOutRequest = new DeskCheckOutRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DeskScheduleRequest$Type extends MessageType<DeskScheduleRequest> {
    constructor() {
        super("DeskScheduleRequest", [
            { no: 1, name: "customer_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "deskID", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "startTime", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "endTime", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "timeZone", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "accountID", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<DeskScheduleRequest>): DeskScheduleRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.customerId = "";
        message.deskID = "";
        message.startTime = "";
        message.endTime = "";
        message.timeZone = "";
        message.accountID = "";
        if (value !== undefined)
            reflectionMergePartial<DeskScheduleRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DeskScheduleRequest): DeskScheduleRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string customer_id */ 1:
                    message.customerId = reader.string();
                    break;
                case /* string deskID */ 2:
                    message.deskID = reader.string();
                    break;
                case /* string startTime */ 4:
                    message.startTime = reader.string();
                    break;
                case /* string endTime */ 5:
                    message.endTime = reader.string();
                    break;
                case /* string timeZone */ 6:
                    message.timeZone = reader.string();
                    break;
                case /* string accountID */ 7:
                    message.accountID = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DeskScheduleRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string customer_id = 1; */
        if (message.customerId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.customerId);
        /* string deskID = 2; */
        if (message.deskID !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.deskID);
        /* string startTime = 4; */
        if (message.startTime !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.startTime);
        /* string endTime = 5; */
        if (message.endTime !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.endTime);
        /* string timeZone = 6; */
        if (message.timeZone !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.timeZone);
        /* string accountID = 7; */
        if (message.accountID !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.accountID);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message DeskScheduleRequest
 */
export const DeskScheduleRequest = new DeskScheduleRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DeskScheduleResponse$Type extends MessageType<DeskScheduleResponse> {
    constructor() {
        super("DeskScheduleResponse", [
            { no: 1, name: "timeSlots", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => BusyTimeSlots }
        ]);
    }
    create(value?: PartialMessage<DeskScheduleResponse>): DeskScheduleResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.timeSlots = [];
        if (value !== undefined)
            reflectionMergePartial<DeskScheduleResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DeskScheduleResponse): DeskScheduleResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated ggevent.BusyTimeSlots timeSlots */ 1:
                    message.timeSlots.push(BusyTimeSlots.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DeskScheduleResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated ggevent.BusyTimeSlots timeSlots = 1; */
        for (let i = 0; i < message.timeSlots.length; i++)
            BusyTimeSlots.internalBinaryWrite(message.timeSlots[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message DeskScheduleResponse
 */
export const DeskScheduleResponse = new DeskScheduleResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MultipleDeskScheduleRequest$Type extends MessageType<MultipleDeskScheduleRequest> {
    constructor() {
        super("MultipleDeskScheduleRequest", [
            { no: 1, name: "customer_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "desksID", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "startTime", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "endTime", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "timeZome", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<MultipleDeskScheduleRequest>): MultipleDeskScheduleRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.customerId = "";
        message.desksID = [];
        message.startTime = "";
        message.endTime = "";
        message.timeZome = "";
        if (value !== undefined)
            reflectionMergePartial<MultipleDeskScheduleRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MultipleDeskScheduleRequest): MultipleDeskScheduleRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string customer_id */ 1:
                    message.customerId = reader.string();
                    break;
                case /* repeated string desksID */ 2:
                    message.desksID.push(reader.string());
                    break;
                case /* string startTime */ 4:
                    message.startTime = reader.string();
                    break;
                case /* string endTime */ 5:
                    message.endTime = reader.string();
                    break;
                case /* string timeZome */ 6:
                    message.timeZome = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: MultipleDeskScheduleRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string customer_id = 1; */
        if (message.customerId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.customerId);
        /* repeated string desksID = 2; */
        for (let i = 0; i < message.desksID.length; i++)
            writer.tag(2, WireType.LengthDelimited).string(message.desksID[i]);
        /* string startTime = 4; */
        if (message.startTime !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.startTime);
        /* string endTime = 5; */
        if (message.endTime !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.endTime);
        /* string timeZome = 6; */
        if (message.timeZome !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.timeZome);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message MultipleDeskScheduleRequest
 */
export const MultipleDeskScheduleRequest = new MultipleDeskScheduleRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MultipleDeskTimeSlot$Type extends MessageType<MultipleDeskTimeSlot> {
    constructor() {
        super("MultipleDeskTimeSlot", [
            { no: 1, name: "deskID", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "timeSlots", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => BusyTimeSlots },
            { no: 3, name: "blocked", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 4, name: "assigned", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<MultipleDeskTimeSlot>): MultipleDeskTimeSlot {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.deskID = "";
        message.timeSlots = [];
        message.blocked = false;
        message.assigned = false;
        if (value !== undefined)
            reflectionMergePartial<MultipleDeskTimeSlot>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MultipleDeskTimeSlot): MultipleDeskTimeSlot {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string deskID */ 1:
                    message.deskID = reader.string();
                    break;
                case /* repeated ggevent.BusyTimeSlots timeSlots */ 2:
                    message.timeSlots.push(BusyTimeSlots.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* bool blocked */ 3:
                    message.blocked = reader.bool();
                    break;
                case /* bool assigned */ 4:
                    message.assigned = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: MultipleDeskTimeSlot, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string deskID = 1; */
        if (message.deskID !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.deskID);
        /* repeated ggevent.BusyTimeSlots timeSlots = 2; */
        for (let i = 0; i < message.timeSlots.length; i++)
            BusyTimeSlots.internalBinaryWrite(message.timeSlots[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* bool blocked = 3; */
        if (message.blocked !== false)
            writer.tag(3, WireType.Varint).bool(message.blocked);
        /* bool assigned = 4; */
        if (message.assigned !== false)
            writer.tag(4, WireType.Varint).bool(message.assigned);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message MultipleDeskTimeSlot
 */
export const MultipleDeskTimeSlot = new MultipleDeskTimeSlot$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MultipleDeskScheduleResponse$Type extends MessageType<MultipleDeskScheduleResponse> {
    constructor() {
        super("MultipleDeskScheduleResponse", [
            { no: 1, name: "timeSlots", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => MultipleDeskTimeSlot }
        ]);
    }
    create(value?: PartialMessage<MultipleDeskScheduleResponse>): MultipleDeskScheduleResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.timeSlots = [];
        if (value !== undefined)
            reflectionMergePartial<MultipleDeskScheduleResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MultipleDeskScheduleResponse): MultipleDeskScheduleResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated MultipleDeskTimeSlot timeSlots */ 1:
                    message.timeSlots.push(MultipleDeskTimeSlot.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: MultipleDeskScheduleResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated MultipleDeskTimeSlot timeSlots = 1; */
        for (let i = 0; i < message.timeSlots.length; i++)
            MultipleDeskTimeSlot.internalBinaryWrite(message.timeSlots[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message MultipleDeskScheduleResponse
 */
export const MultipleDeskScheduleResponse = new MultipleDeskScheduleResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DeskCheckInDefferRequest$Type extends MessageType<DeskCheckInDefferRequest> {
    constructor() {
        super("DeskCheckInDefferRequest", [
            { no: 1, name: "eventID", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "customerID", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "eventStatus", kind: "enum", T: () => ["ggevent.EventCheckInStatus", EventCheckInStatus] }
        ]);
    }
    create(value?: PartialMessage<DeskCheckInDefferRequest>): DeskCheckInDefferRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.eventID = "";
        message.customerID = "";
        message.eventStatus = 0;
        if (value !== undefined)
            reflectionMergePartial<DeskCheckInDefferRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DeskCheckInDefferRequest): DeskCheckInDefferRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string eventID */ 1:
                    message.eventID = reader.string();
                    break;
                case /* string customerID */ 2:
                    message.customerID = reader.string();
                    break;
                case /* ggevent.EventCheckInStatus eventStatus */ 3:
                    message.eventStatus = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DeskCheckInDefferRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string eventID = 1; */
        if (message.eventID !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.eventID);
        /* string customerID = 2; */
        if (message.customerID !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.customerID);
        /* ggevent.EventCheckInStatus eventStatus = 3; */
        if (message.eventStatus !== 0)
            writer.tag(3, WireType.Varint).int32(message.eventStatus);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message DeskCheckInDefferRequest
 */
export const DeskCheckInDefferRequest = new DeskCheckInDefferRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListDesksBookingRequest$Type extends MessageType<ListDesksBookingRequest> {
    constructor() {
        super("ListDesksBookingRequest", [
            { no: 1, name: "startTime", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "endTime", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "customerID", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "desksId", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ListDesksBookingRequest>): ListDesksBookingRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.startTime = "";
        message.endTime = "";
        message.customerID = "";
        message.desksId = [];
        if (value !== undefined)
            reflectionMergePartial<ListDesksBookingRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListDesksBookingRequest): ListDesksBookingRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string startTime */ 1:
                    message.startTime = reader.string();
                    break;
                case /* string endTime */ 2:
                    message.endTime = reader.string();
                    break;
                case /* string customerID */ 4:
                    message.customerID = reader.string();
                    break;
                case /* repeated string desksId */ 5:
                    message.desksId.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListDesksBookingRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string startTime = 1; */
        if (message.startTime !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.startTime);
        /* string endTime = 2; */
        if (message.endTime !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.endTime);
        /* string customerID = 4; */
        if (message.customerID !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.customerID);
        /* repeated string desksId = 5; */
        for (let i = 0; i < message.desksId.length; i++)
            writer.tag(5, WireType.LengthDelimited).string(message.desksId[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ListDesksBookingRequest
 */
export const ListDesksBookingRequest = new ListDesksBookingRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListDesksBookingObject$Type extends MessageType<ListDesksBookingObject> {
    constructor() {
        super("ListDesksBookingObject", [
            { no: 1, name: "deskId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "deskName", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "deskTags", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "deskLocationPath", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => DeskLocationItem },
            { no: 5, name: "events", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => DeskBookingEvent }
        ]);
    }
    create(value?: PartialMessage<ListDesksBookingObject>): ListDesksBookingObject {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.deskId = "";
        message.deskName = "";
        message.deskTags = [];
        message.deskLocationPath = [];
        message.events = [];
        if (value !== undefined)
            reflectionMergePartial<ListDesksBookingObject>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListDesksBookingObject): ListDesksBookingObject {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string deskId */ 1:
                    message.deskId = reader.string();
                    break;
                case /* string deskName */ 2:
                    message.deskName = reader.string();
                    break;
                case /* repeated string deskTags */ 3:
                    message.deskTags.push(reader.string());
                    break;
                case /* repeated DeskLocationItem deskLocationPath */ 4:
                    message.deskLocationPath.push(DeskLocationItem.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated DeskBookingEvent events */ 5:
                    message.events.push(DeskBookingEvent.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListDesksBookingObject, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string deskId = 1; */
        if (message.deskId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.deskId);
        /* string deskName = 2; */
        if (message.deskName !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.deskName);
        /* repeated string deskTags = 3; */
        for (let i = 0; i < message.deskTags.length; i++)
            writer.tag(3, WireType.LengthDelimited).string(message.deskTags[i]);
        /* repeated DeskLocationItem deskLocationPath = 4; */
        for (let i = 0; i < message.deskLocationPath.length; i++)
            DeskLocationItem.internalBinaryWrite(message.deskLocationPath[i], writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* repeated DeskBookingEvent events = 5; */
        for (let i = 0; i < message.events.length; i++)
            DeskBookingEvent.internalBinaryWrite(message.events[i], writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ListDesksBookingObject
 */
export const ListDesksBookingObject = new ListDesksBookingObject$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListDesksBookingResponse$Type extends MessageType<ListDesksBookingResponse> {
    constructor() {
        super("ListDesksBookingResponse", [
            { no: 1, name: "data", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => ListDesksBookingObject }
        ]);
    }
    create(value?: PartialMessage<ListDesksBookingResponse>): ListDesksBookingResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.data = [];
        if (value !== undefined)
            reflectionMergePartial<ListDesksBookingResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListDesksBookingResponse): ListDesksBookingResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated ListDesksBookingObject data */ 1:
                    message.data.push(ListDesksBookingObject.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListDesksBookingResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated ListDesksBookingObject data = 1; */
        for (let i = 0; i < message.data.length; i++)
            ListDesksBookingObject.internalBinaryWrite(message.data[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ListDesksBookingResponse
 */
export const ListDesksBookingResponse = new ListDesksBookingResponse$Type();
/**
 * @generated ServiceType for protobuf service DeskBooking
 */
export const DeskBooking = new ServiceType("DeskBooking", [
    { name: "CreateDeskBooking", options: { "google.api.http": { post: "/v1/desk", body: "*" } }, I: CreateDeskBookingRequest, O: DeskBookingEvent },
    { name: "UpdateDeskBooking", options: { "google.api.http": { patch: "/v1/desk", body: "*" } }, I: UpdateDeskBookingRequest, O: DeskBookingEvent },
    { name: "DeleteDeskBooking", options: { "google.api.http": { delete: "/v1/desk", body: "*" } }, I: DeleteDeskBookingRequest, O: Empty },
    { name: "RetrieveDeskBooking", options: { "google.api.http": { get: "/v1/desk/retrieve", body: "*" } }, I: RetrieveDeskBookingRequest, O: DeskBookingEvent },
    { name: "ListDeskBooking", options: { "google.api.http": { get: "/v1/desk", body: "*" } }, I: ListDeskBookingRequest, O: ListDesksResponse },
    { name: "ListDesksBooking", options: { "google.api.http": { get: "/v1/desks", body: "*" } }, I: ListDesksBookingRequest, O: ListDesksBookingResponse },
    { name: "SearchMatchedDesks", options: { "google.api.http": { get: "/v1/desk/search", body: "*" } }, I: MatchingDeskRequest, O: ListDesksResponse },
    { name: "SearchPersonBooking", options: { "google.api.http": { get: "/v1/desk/search/person" } }, I: PersonBookingRequest, O: ListDesksResponse },
    { name: "NearbyDesks", options: { "google.api.http": { get: "/v1/desk/nearby" } }, I: MatchingDeskRequest, O: ListDesksResponse },
    { name: "DeskCheckIn", options: { "google.api.http": { post: "/v1/desk/checkin", body: "*" } }, I: DeskCheckInRequest, O: DeskCheckInResponse },
    { name: "DeskCheckOut", options: { "google.api.http": { post: "/v1/desk/checkout", body: "*" } }, I: DeskCheckOutRequest, O: Empty },
    { name: "DeskSchedule", options: { "google.api.http": { post: "/v1/desk/schedule", body: "*" } }, I: DeskScheduleRequest, O: DeskScheduleResponse },
    { name: "MultipleDeskSchedule", options: { "google.api.http": { post: "/v1/desks/schedule", body: "*" } }, I: MultipleDeskScheduleRequest, O: MultipleDeskScheduleResponse },
    { name: "DeskDefferChekInNotification", options: { "google.api.http": { post: "/v1/desk/notification", body: "*" } }, I: DeskCheckInDefferRequest, O: Empty }
]);
