import React from "react";
import { useDesksRequests } from "../../../../api/graphql/desks/useDesksRequests";
import {
  ListAllDesksBookingRequest,
  useDeskBookingRequest,
} from "../../../../api/grpc/desk/useDeskBookingRequest";
import useGrpcQuery from "../../../../lib/hooks/useGrpcQuery";
import { useAllReservationContext } from "../../shared/context/AllReservationContext/AllReservationContext";
import { format } from "date-fns";
import { ListDesksBookingResponse } from "../../../../api/grpc/desk/desk";
import { ReservationCard } from "../shared/ReservationCard";
import { WorkplaceFields } from "../../../../api/graphql/workplaces";
import { HandleLoadingState } from "../../../shared/HandleLoadingState/HandleLoadingState";
import { LoadingBox } from "../../../shared/LoadingBox/LoadingBox";
import { HandleRequestState } from "../../../shared/HandleRequestState/HandleRequestState";

interface Props {
  pickedDesks: WorkplaceFields[];
}

export const DesksReservations = ({ pickedDesks }: Props) => {
  const { listAllDesksBooking } = useDeskBookingRequest();
  const { startDate } = useAllReservationContext();

  const {
    loading,
    error,
    data,
    refetch: refetchDesks,
  } = useGrpcQuery<ListAllDesksBookingRequest, ListDesksBookingResponse>({
    method: listAllDesksBooking,
    variables: {
      startTime: format(startDate || new Date(), "yyyy-MM-dd"),
      endTime: format(startDate || new Date(), "yyyy-MM-dd"),
      desksId: pickedDesks.map((workspace) => workspace.id),
    },
  });

  return (
    <HandleLoadingState loading={loading} loadingPlaceholder={<LoadingBox />}>
      <HandleRequestState
        state={!!error}
        placeholder={<div>{error?.message}</div>}
      >
        {data?.data.map((item) => (
          <div key={item.deskId}>
            <ReservationCard
              type="desk"
              name={item.deskName}
              events={item.events}
              tags={item.deskTags.join(", ")}
              locationPath={item.deskLocationPath}
              workplaceID={item.deskId}
              refetchDesk={refetchDesks}
            />
          </div>
        ))}
      </HandleRequestState>
    </HandleLoadingState>
  );
};
