import React, { useMemo } from "react";
import { useApolloClient } from "@apollo/client";
import { useRoomReservations } from "../../../../Reservations/helpers/useRoomReservations";
import { useReservationHelpers } from "../../../../Reservations/helpers/reservationUpdateHelpers";
import { useAuthContext } from "../../../../../../lib/context/Auth/AuthContext";
import { useTimeZoneContext } from "../../../../../../lib/context/TimeZone/TimeZoneContext";
import { useFormatReservationDate as formatReservationDate } from "../../../../lib/datePickerHelper";
import { utcToZonedTime } from "date-fns-tz";

import { ModalFooter } from "reactstrap";
import { ROOM_FIELDS_FRAGMENT } from "../../../../../../api/graphql/rooms/rooms";
import { Button } from "../../../../../shared/Button/Button";
import { dateToHoursAndMinutes } from "../../../../lib/dateInputConvert";
import { UserModalLoading } from "../../../../shared/UserModal/UserModalLoading";
import { BookingsModalBody } from "../../shared/BookingsModalBody";
import { PersonalCalendar } from "../../../../../../api/grpc/calendarprovider/calendarprovider";
import { CheckInRequiredStatus } from "../../../../../../api/grpc/booking/ggevent/ggevent";

interface Props {
  id: string;
  toggleModal: () => void;
  refetch: () => void;
  personalCalendar: PersonalCalendar[];
}

export const BookingsMapDeleteRoomForm = ({
  id,
  toggleModal,
  refetch,
  personalCalendar,
}: Props) => {
  const client = useApolloClient();
  const { timeZone } = useTimeZoneContext();

  const { user } = useAuthContext();
  const { deleteRoomReservation } = useReservationHelpers();

  let calendarsLength = personalCalendar.length;

  const { roomData, loadingRoom, errorRoom } = useRoomReservations();

  let choosedRoom = roomData?.meetings.filter(
    (room) => room.workplaceID === id
  );

  // we need to have a delay when we refetch data after remove/delete room reservation
  // because office o365 takes some time to send the updates
  const refetchAfterDelete = () => {
    setTimeout(() => {
      return refetch();
    }, 2000);
  };

  const workplaceData = client.readFragment({
    id: `Room:${id}`,
    fragmentName: "RoomFields",
    fragment: ROOM_FIELDS_FRAGMENT,
  });

  const handleRoomDelete = async () => {
    if (!!!choosedRoom?.length) {
      return;
    }
    return await deleteRoomReservation(
      calendarsLength > 0 ? "" : workplaceData?.calendarId,
      calendarsLength > 0
        ? personalCalendar[0].iD
        : workplaceData?.calendarProviderId,
      choosedRoom[0]?.metaData,
      refetchAfterDelete,
      choosedRoom[0].checkInRequiredStatus ===
        CheckInRequiredStatus.CHECK_IN_REQUIRED,
      user?.email || ""
    );
  };

  const isLoading = useMemo<boolean>(() => {
    return loadingRoom || roomData === undefined;
  }, [loadingRoom, roomData]);

  return (
    <>
      {!!!calendarsLength ? (
        <>You need personal integration for this.</>
      ) : (
        <>
          {isLoading ? (
            <UserModalLoading />
          ) : errorRoom ? (
            <div>
              Error loading reservation data, please reload and try again
            </div>
          ) : (
            <>
              {!!choosedRoom?.length ? (
                <>
                  <BookingsModalBody
                    title="Delete reservation?"
                    icon="delete-reservation"
                    workspaceName={workplaceData.name}
                    workspaceIconType="desk-reserve"
                    day={formatReservationDate(
                      utcToZonedTime(
                        new Date(choosedRoom ? choosedRoom[0].startTime : ""),
                        timeZone
                      )
                    )}
                    startTime={dateToHoursAndMinutes(
                      new Date(choosedRoom[0]?.startTime || ""),
                      timeZone
                    )}
                    endTime={dateToHoursAndMinutes(
                      new Date(choosedRoom[0]?.endTime || ""),
                      timeZone
                    )}
                    userName={user?.displayName || ""}
                    locationPath={[]}
                    type="delete"
                    allDay={choosedRoom[0].allDay}
                    subject={choosedRoom[0].title}
                  />

                  <ModalFooter className="modal-footer-row">
                    <Button
                      size="small"
                      color="outline-secondary"
                      title="Cancel"
                      onClick={toggleModal}
                    />

                    <Button
                      size="small"
                      color="primary"
                      title="Delete"
                      onClick={handleRoomDelete}
                    />
                  </ModalFooter>
                </>
              ) : (
                <div>Only the organizer can delete this meeting.</div>
              )}
            </>
          )}
        </>
      )}
    </>
  );
};
