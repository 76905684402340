import { useMemo } from "react";

import { useAuthContext } from "../../../lib/context/Auth/AuthContext";

import { transport } from "../grpcTransport";

import { HistoryEventsRequest, TimeRangeEventsRequest } from "./insights";

import { InsightsClient } from "./insights.client";

export type TimeRangeRequest = Omit<TimeRangeEventsRequest, "customerID">;

export const useInsightsRequests = () => {
  const { user, tokenInterceptor } = useAuthContext();

  const insightsClient = useMemo(() => new InsightsClient(transport), []);

  const getGeneralRoomStats = async ({
    locationID,
    startDate,
    endDate,
    timeZone,
  }: TimeRangeRequest) => {
    if (!user) {
      throw new Error("User not found!");
    }

    return insightsClient.roomGeneralStats(
      {
        customerID: user.customerid,
        locationID: locationID,
        startDate: startDate,
        endDate: endDate,
        timeZone,
      },
      {
        interceptors: [tokenInterceptor],
      }
    );
  };

  const getAllRoomsOfLocation = async ({
    locationID,
    startDate,
    endDate,
    timeZone,
  }: TimeRangeRequest) => {
    if (!user) {
      throw new Error("User not found!");
    }

    return insightsClient.generalStatsByRooms(
      {
        customerID: user.customerid,
        locationID: locationID,
        startDate: startDate,
        endDate: endDate,
        timeZone,
      },
      {
        interceptors: [tokenInterceptor],
      }
    );
  };

  const getRoomsTimeRangeEvents = async ({
    locationID,
    startDate,
    endDate,
    timeZone,
  }: TimeRangeRequest) => {
    if (!user) {
      throw new Error("User not found!");
    }

    return insightsClient.roomTimeRangeEvents(
      {
        customerID: user.customerid,
        locationID: locationID,
        startDate: startDate,
        endDate: endDate,
        timeZone,
      },
      {
        interceptors: [tokenInterceptor],
      }
    );
  };

  const getTotalOccupancy = async ({
    locationID,
    startDate,
    endDate,
    timeZone,
  }: TimeRangeRequest) => {
    if (!user) {
      throw new Error("User not found!");
    }

    return insightsClient.totalOccupancy(
      {
        customerID: user.customerid,
        locationID: locationID,
        startDate: startDate,
        endDate: endDate,
        timeZone,
      },
      {
        interceptors: [tokenInterceptor],
      }
    );
  };

  const getRoomHeatMapEvents = async ({
    locationID,
    startDate,
    endDate,
    timeZone,
  }: TimeRangeRequest) => {
    if (!user) {
      throw new Error("User not found!");
    }

    return insightsClient.roomEventsHeatMap(
      {
        customerID: user.customerid,
        locationID: locationID,
        startDate: startDate,
        endDate: endDate,
        timeZone,
      },
      {
        interceptors: [tokenInterceptor],
      }
    );
  };

  const getRoomBehavior = async ({
    locationID,
    startDate,
    endDate,
    timeZone,
  }: TimeRangeRequest) => {
    if (!user) {
      throw new Error("User not found!");
    }

    return insightsClient.roomMeetingBehavior(
      {
        customerID: user.customerid,
        locationID: locationID,
        startDate: startDate,
        endDate: endDate,
        timeZone,
      },
      {
        interceptors: [tokenInterceptor],
      }
    );
  };

  const getRoomReservationFlor = async ({
    locationID,
    startDate,
    endDate,
    timeZone,
  }: TimeRangeRequest) => {
    if (!user) {
      throw new Error("User not found!");
    }

    return insightsClient.roomSankeyChart(
      {
        customerID: user.customerid,
        locationID: locationID,
        startDate: startDate,
        endDate: endDate,
        timeZone,
      },
      {
        interceptors: [tokenInterceptor],
      }
    );
  };

  const getRoomReservationsHistory = async ({
    locationID,
    startDate,
    endDate,
    timeZone,
    limit,
    offset,
    organizers,
  }: Omit<HistoryEventsRequest, "customerID">) => {
    if (!user) {
      throw new Error("User not found!");
    }

    return insightsClient.roomHistoryEvents(
      {
        customerID: user.customerid,
        locationID,
        startDate,
        endDate,
        timeZone,
        limit,
        offset,
        organizers,
      },
      {
        interceptors: [tokenInterceptor],
      }
    );
  };

  const exportRoomInsights = async ({
    locationID,
    startDate,
    endDate,
    timeZone,
  }: TimeRangeRequest) => {
    if (!user) {
      throw new Error("User not found!");
    }

    return insightsClient.roomInsightsExport(
      {
        customerID: user.customerid,
        locationID: locationID,
        startDate: startDate,
        endDate: endDate,
        timeZone,
      },
      {
        interceptors: [tokenInterceptor],
      }
    );
  };

  return {
    getGeneralRoomStats,
    getAllRoomsOfLocation,
    getRoomsTimeRangeEvents,
    getTotalOccupancy,
    getRoomHeatMapEvents,
    getRoomBehavior,
    getRoomReservationFlor,
    exportRoomInsights,
    getRoomReservationsHistory,
  };
};
