import {
  Attendee,
  CheckInRequiredStatus,
  Event,
  EventCheckInStatus,
} from "../../api/grpc/booking/ggevent/ggevent";

export const defaultAttendee: Attendee = {
  displayName: "",
  email: "",
  firstName: "",
  id: "",
  isHuman: true,
  status: "",
  surName: "",
  allowToFind: true,
  mode1Organizer: true,
};

// this object defined event data that are static
export const roomEvent: Event = {
  isCheckOut: false,
  workplaceLocationPath: [],
  tags: [],
  updateTime: "",
  metaData: {},
  description: "",
  onlineMeetingUrl: "",
  workplaceID: "",
  headcount: 0,
  lockPin: "",
  extendedProperties: {},
  locations: [],
  providerType: "",

  //dynamic data below that will be owerriten:
  allDay: false,
  attendees: [defaultAttendee],
  checkInRequiredStatus: CheckInRequiredStatus.CHECK_IN_NOT_REQUIRED,
  creationTime: "",
  startTime: "",
  endTime: "",
  isPrivate: false,
  isOnlineMeeting: false,
  eventCheckInStatus: EventCheckInStatus.CHECK_IN_NONE,
  title: "",
  eventTimeZone: "",
  organizer: defaultAttendee,
  checkInReminderSend: false,
};
