import React, { PropsWithChildren, useState } from "react";
import {
  AllReservationContext,
  DayOptionType,
  IAllReservationContext,
} from "./AllReservationContext";
import { WorkplaceFields } from "../../../../../api/graphql/workplaces";
import { useDeskBookingRequest } from "../../../../../api/grpc/desk/useDeskBookingRequest";
import { format } from "date-fns";
import { toast } from "react-toastify";
import { ListDesksBookingObject } from "../../../../../api/grpc/desk/desk";

export const AllReservationContextProvider = ({
  children,
}: PropsWithChildren<{
  children: React.ReactNode;
}>) => {
  const [startDate, setStartDate] = useState<Date | undefined>(undefined);

  const [openWorkspacePicker, setOpenWorkspacePicker] = useState(false);
  const [pickedWorkspaces, setPickedWorkspaces] = useState<WorkplaceFields[]>(
    []
  );

  const context: IAllReservationContext = {
    startDate,
    setStartDate,
    openWorkspacePicker,
    setOpenWorkspacePicker,
    pickedWorkspaces,
    setPickedWorkspaces,
  };

  return (
    <AllReservationContext.Provider value={context}>
      {children}
    </AllReservationContext.Provider>
  );
};
