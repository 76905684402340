import React, { Dispatch, SetStateAction } from "react";
import { WorkplaceFields } from "../../../../../api/graphql/workplaces";
import { ListDesksBookingObject } from "../../../../../api/grpc/desk/desk";

export type DayOptionType = "today" | "tomorrow";

export interface IAllReservationContext {
  startDate?: Date;
  setStartDate: Dispatch<React.SetStateAction<Date | undefined>>;
  openWorkspacePicker: boolean;
  setOpenWorkspacePicker: Dispatch<SetStateAction<boolean>>;
  pickedWorkspaces: WorkplaceFields[];
  setPickedWorkspaces: Dispatch<SetStateAction<WorkplaceFields[]>>;
}

const values: IAllReservationContext = {
  startDate: new Date(),
  setStartDate: () => {},
  openWorkspacePicker: false,
  setOpenWorkspacePicker: () => {},
  pickedWorkspaces: [],
  setPickedWorkspaces: () => {},
};

export const AllReservationContext = React.createContext(values);

export const useAllReservationContext = () => {
  const context = React.useContext(AllReservationContext);

  if (!context) {
    throw new Error(
      "useAllReservationContext must be used inside of AllReservationContext"
    );
  }

  return context;
};
