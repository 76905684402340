import React, { useState } from "react";

import { useAllReservationContext } from "../context/AllReservationContext/AllReservationContext";

import { WorkspacePicker } from "../../../shared/WorkspacePicker/WorkspacePicker";
import { AllReservationTreeLevel } from "./AllReservationTreeLevel";
import { WayFinderWorkspaceFooterPicker } from "../../../WayFinder/WayFinderPicker/WayFinderWorkspaceFooterPicker";

export const AllReservationWorkspacePicker = () => {
  const { pickedWorkspaces, setPickedWorkspaces, setOpenWorkspacePicker } =
    useAllReservationContext();
  const [innerWorkspaces, setInnerWorkspaces] = useState(pickedWorkspaces);

  return (
    <WorkspacePicker
      title="Calendar picker"
      description=" Select room and desk calendars below"
      message="message"
      closePicker={() => setOpenWorkspacePicker(false)}
      footer={
        <WayFinderWorkspaceFooterPicker
          clearWorkspaces={() => {
            if (innerWorkspaces.length > 0) {
              setInnerWorkspaces([]);
            }

            if (pickedWorkspaces.length > 0) {
              setPickedWorkspaces([]);
            }
          }}
          handleConfirm={() => {
            setPickedWorkspaces(innerWorkspaces);

            setOpenWorkspacePicker(false);
          }}
          desksCounter={0}
          roomsCounter={0}
        >
          <span className="pt-3 d-block text-center WayFinderFormWorkspaces__counter mb-5">
            {pickedWorkspaces.length} of max 5 workspace calendars selected
          </span>
        </WayFinderWorkspaceFooterPicker>
      }
    >
      {({ rootWorkplaces }) => {
        return rootWorkplaces.map((item) => {
          if (typeof item !== "object") {
            return null;
          }

          return item.map((workplace) => (
            <AllReservationTreeLevel
              item={workplace}
              toggleParent={() => {}}
              key={`${workplace.id}-tree-root`}
              pickedWorkspaces={pickedWorkspaces}
              setPickedWorkspaces={setPickedWorkspaces}
              innerWorkspaces={innerWorkspaces}
              setInnerWorkspaces={setInnerWorkspaces}
            />
          ));
        });
      }}
    </WorkspacePicker>
  );
};
