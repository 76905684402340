import { useMemo } from "react";

import { useAuthContext } from "../../../lib/context/Auth/AuthContext";

import { transport } from "../grpcTransport";
import { AccountServiceClient } from "./account.client";
import {
  Account,
  DisableManualMFARequest,
  DisableMFARequest,
  UpdateAccount,
  VerifyEmailChangeRequest,
  VerifyEmailRequest,
} from "./account";

export type Partial<T> = {
  [P in keyof T]?: T[P];
};

export const useAccountRequests = () => {
  const { user, tokenInterceptor } = useAuthContext();
  const authClient = useMemo(() => new AccountServiceClient(transport), []);

  const uploadProfilePicture = async (
    image: Uint8Array,
    format: "image/jpeg" | "image/png"
  ) => {
    if (!user) {
      throw new Error("User was not found!");
    }

    return await authClient.uploadProfilePicture(
      {
        imageData: image,
        customerId: user.customerid,
        accountId: user.claims.user_id,
        imageType: format,
        base64: new Uint8Array(0),
        url: "",
      },
      {
        interceptors: [tokenInterceptor],
      }
    );
  };

  const deleteProfilePicture = async () => {
    if (!user) {
      throw new Error("User was not found!");
    }

    return await authClient.deleteProfilePicture(
      {
        customerId: user.customerid,
        accountId: user.claims.user_id,
      },
      {
        interceptors: [tokenInterceptor],
      }
    );
  };

  const disableAccount = async () => {
    if (!user) {
      throw new Error("User was not found!");
    }

    return await authClient.disableAccount(
      {
        customerId: user.customerid,
        accountId: user.claims.user_id,
        ssoDisabled: true,
      },
      {
        interceptors: [tokenInterceptor],
      }
    );
  };

  const getAccount = async (
    customerId: string,
    accountId: string,
    token: string
  ) => {
    return await authClient.getAccount(
      {
        accountId,
        customerId,
      },
      {
        meta: {
          jwt: token,
        },
      }
    );
  };

  const updateAccount = async (
    accountFields: UpdateAccount,
    updatePaths: string[]
  ) => {
    if (!user) {
      throw new Error("User was not found!");
    }

    return await authClient.updateAccount(
      {
        accountId: user.claims.user_id,
        customerId: user.customerid,
        account: accountFields,
        updateMask: {
          paths: updatePaths,
        },
      },
      {
        interceptors: [tokenInterceptor],
      }
    );
  };

  const requestEmailChange = async (newEmail: string) => {
    if (!user) {
      throw new Error("User was not found!");
    }

    return await authClient.requestEmailChange(
      {
        customerID: user.customerid,
        accountID: user.claims.user_id,
        newEmail: newEmail.toLowerCase(),
      },
      {
        interceptors: [tokenInterceptor],
      }
    );
  };

  const verifyEmailChange = async (variables: VerifyEmailChangeRequest) => {
    return await authClient.verifyEmailChange(variables);
  };

  const verifyAccount = async (variables: VerifyEmailRequest) => {
    return await authClient.verifyEmail(variables);
  };

  const resetPassword = async (email: string) => {
    return await authClient.resetPassword({ email: email.toLowerCase() });
  };

  const disableMFA = async ({ accountID, token }: DisableMFARequest) => {
    return await authClient.disableMFA({
      accountID,
      token,
    });
  };

  const disableManualMFA = async ({ email }: DisableManualMFARequest) => {
    return await authClient.disableManualMFA({
      email,
    });
  };

  const updateAllowedToFind = async (allowToFind: boolean) => {
    if (!user) {
      throw new Error("User was not found!");
    }

    return await authClient.updateAllowToFind(
      {
        customerId: user.customerid,
        accountId: user.claims.user_id,
        allowToFind: allowToFind,
      },
      {
        interceptors: [tokenInterceptor],
      }
    );
  };

  const setRecoveryEmail = async (email: string) => {
    if (!user) {
      throw new Error("User was not found!");
    }

    return await authClient.setRecoveryEmail(
      {
        accountID: user.claims.user_id,
        email,
      },
      {
        interceptors: [tokenInterceptor],
      }
    );
  };

  const sendRecoveryEmail = async (
    primaryEmail: string,
    secondaryEmail: string
  ) => {
    return await authClient.sendRecoveryEmail({
      primaryEmail,
      secondaryEmail,
    });
  };

  const updateUserTimeZone = (timeZone: string): Promise<Account> => {
    if (!user) {
      throw new Error("User was not found!");
    }

    const { response } = authClient.updateTimeZone(
      {
        accountId: user.claims.user_id,
        customerId: user.customerid,
        timeZone,
      },
      {
        interceptors: [tokenInterceptor],
      }
    );

    return response;
  };

  return {
    getAccount,
    verifyAccount,
    resetPassword,
    updateAccount,
    disableAccount,
    disableMFA,
    disableManualMFA,
    deleteProfilePicture,
    uploadProfilePicture,
    updateAllowedToFind,
    requestEmailChange,
    verifyEmailChange,
    updateUserTimeZone,
    setRecoveryEmail,
    sendRecoveryEmail,
  };
};
