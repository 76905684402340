import React from "react";
import { ReservationCard } from "./shared/ReservationCard";
import { MainResources } from "../../../lib/types/main.types";
import { AddWorkspaceCalendar } from "./shared/AddWorkspaceCalendar";
import useGrpcQuery from "../../../lib/hooks/useGrpcQuery";
import {
  ListAllDesksBookingRequest,
  useDeskBookingRequest,
} from "../../../api/grpc/desk/useDeskBookingRequest";
import {
  ListDesksBookingRequest,
  ListDesksBookingResponse,
} from "../../../api/grpc/desk/desk";
import { format } from "date-fns";
import { HandleLoadingState } from "../../shared/HandleLoadingState/HandleLoadingState";
import { LoadingBox } from "../../shared/LoadingBox/LoadingBox";
import { useAllReservationContext } from "../shared/context/AllReservationContext/AllReservationContext";
import { DesksReservations } from "./DesksReservations/DesksReservations";

export const AllReservationsContent = () => {
  const { pickedWorkspaces } = useAllReservationContext();

  const pickedDesks = pickedWorkspaces.filter(
    (workspace) => workspace.__typename === "Desk"
  );

  return (
    <div className="AllReservationsContent mb-4">
      {pickedDesks.length > 0 && (
        // <>
        //   {data.map((item) => (
        //     <ReservationCard
        //       key={item.id}
        //       name={item.name}
        //       type={item.type as MainResources}
        //     />
        //   ))}
        // </>
        <DesksReservations pickedDesks={pickedDesks} />
      )}

      {pickedWorkspaces.length < 5 && <AddWorkspaceCalendar />}
    </div>
    // </HandleLoadingState>
  );
};
