import React from "react";
import { differenceInDays, format } from "date-fns";
import { useBookingsContext } from "../../Context/BookingsContext";
import { utcToZonedTime } from "date-fns-tz";
import { useTimeZoneContext } from "../../../../lib/context/TimeZone/TimeZoneContext";

import { MatchingDesk } from "../../../../api/grpc/desk/desk";
import {
  StructureTable,
  StructureTableColumn,
} from "../../../shared/StructureTable/StructureTable";
import { WorkplacePrefixIcon } from "../../../Workplaces/WorkplaceExpandedList/WorkplacePrefixIcon/WorkplacePrefixIcon";
import { trimText } from "../../../../lib/helpers/trimText";
import { MatchedWorkplaceActions } from "../../shared/MatchedWorkplaceActions";
import { trimArray } from "../../../../lib/helpers/trimArray";
import { Icon } from "../../../shared/Icon/Icon";

interface Props {
  desks: MatchingDesk[];
}

const colums: StructureTableColumn[] = [
  {
    key: "workplace_icon",
    header: "",
  },
  {
    key: "name",
    header: (
      <div className="flex-a-center">
        <Icon icon="location-search" />
        <span className="pl-1">Desk</span>
      </div>
    ),
  },
  {
    key: "nextReservation",
    header: (
      <div className="flex-a-center">
        <Icon icon="next-reservation" />
        <span className="pl-1">Next reservation</span>
      </div>
    ),
  },
  // {
  //   key: "description",
  //   header: (
  //     <div className="flex-a-center">
  //       <Icon icon="description-icon" />
  //       <span className="pl-1">Description</span>
  //     </div>
  //   ),
  // },
  {
    key: "bookingsTags",
    header: (
      <div className="flex-a-center">
        <Icon icon="tags-search" />
        <span className="pl-1">Tags</span>
      </div>
    ),
  },
  {
    key: "actions",
    header: "",
  },
];

export const BookingsDesksTable = ({ desks }: Props) => {
  const { timeZone } = useTimeZoneContext();
  const { day } = useBookingsContext();
  const isMobile =
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    );

  const rows = desks.map((desk) => {
    return {
      workplace_icon: <WorkplacePrefixIcon icon="desk_icon_18x18" />,
      name: (
        <h4 className="Bookings__desk" title={desk.deskName || "-"}>
          {trimText(desk.deskName || "-", 22)}
        </h4>
      ),
      nextReservation: !!desk.upcomingEvent.length ? (
        <span>
          {format(
            utcToZonedTime(new Date(desk.upcomingEvent), timeZone),
            differenceInDays(
              utcToZonedTime(new Date(day), timeZone),
              utcToZonedTime(new Date(desk.upcomingEvent), timeZone)
            ) === 0
              ? "HH:mm"
              : "dd MMM HH:mm"
          )}
        </span>
      ) : (
        <span className="pb-1 Bookings__empty">-</span>
      ),
      // description: <span>{trimText(desk.description || "-", 30)}</span>,
      bookingsTags: (
        <>
          <span>{trimArray(desk.tagsFilter)}</span>
          {desk.tagsFilter.length > 1 && (
            <div>{desk.tagsFilter.join(", ")}</div>
          )}
        </>
      ),
      actions: (
        <MatchedWorkplaceActions
          workspaceId={desk.deskID}
          type="desk"
          locationPath={desk.locationPath}
        />
      ),
    };
  });
  return (
    <div>
      <StructureTable
        columns={colums}
        rows={rows}
        className="StructureTable--rooms StructureTable--desks"
        isMobile={isMobile}
      />
    </div>
  );
};
