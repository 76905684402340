import React from "react";
import cs from "classnames";
import { IconDefinition } from "../../../shared/Icon/Icon.generated";
import { Icon } from "../../../shared/Icon/Icon";

interface Props {
  icon: IconDefinition;
  className?: string;
  text?: string;
  element?: React.ReactNode;
}

export const ReservationCardElement = ({
  icon,
  className,
  text,
  element,
}: Props) => {
  return (
    <div className={cs("ReservationCardElement flex-a-center", className)}>
      <Icon icon={icon} />

      {text ? (
        <span className="pl-2 d-block overflow-text">{text}</span>
      ) : (
        element
      )}
    </div>
  );
};
