import React, { useMemo, useRef, useState } from "react";
import { useApolloClient } from "@apollo/client";
import { Button } from "../../../components/shared/Button/Button";
import { differenceInMinutes } from "date-fns";
import { utcToZonedTime, zonedTimeToUtc } from "date-fns-tz";
import { toast } from "react-toastify";
import { useAuthContext } from "../../../lib/context/Auth/AuthContext";
import { useBookingsContext } from "../Context/BookingsContext";
import { useRouter } from "../../../lib/hooks/useRouter";
import { useReserveHelpers } from "../lib/reserveHelpers";
import { useOutsideClickDetection } from "../../../lib/hooks/useOutsideClickDetection";
import { useTimeZoneContext } from "../../../lib/context/TimeZone/TimeZoneContext";
import { useCalendarContext } from "../../../lib/context/Calendar/CalendarContext";
import { useBookingsMapContext } from "../BookingsMap/Context/BookingsMapContext";
import { useCalendarRequests } from "../../../api/grpc/calendarprovider/useCalendarRequests";
import { useFormatReservationDate as formatReservationDate } from "../lib/datePickerHelper";

import { capitalizeFirstLetter } from "../../../lib/helpers/capitalizeFirstLetter";
import { BookingsModal } from "./BookingsModal/BookingsModal";
import { DropdownList } from "../../shared/DropdownList/DropdownList";
import { MatchedWorkplaceActionsType } from "../../../views/Bookings/types";
import {
  attachHoursToDate,
  dateToHoursAndMinutes,
} from "../lib/dateInputConvert";
import { Icon, IconDefinition } from "../../../components/shared/Icon/Icon";
import { Attendee } from "../../../api/grpc/booking/ggevent/ggevent";
import {
  getWorkplaceFragment,
  getWorkplaceFragmentName,
} from "../../Workplaces/helpers/getWorkplaceFragment";
import { DeskLocationItem } from "../../../api/grpc/desk/desk";
import { RESERVATIONS_MAP_ROOT_PATH } from "../../../lib/routes/routes";
import { CalendarCredentialsStatus } from "../../../api/grpc/calendarprovider/calendarprovider";
import { MainResources } from "../../../lib/types/main.types";

interface Props {
  workspaceId: string;
  type: MainResources;
  icon?: IconDefinition;
  locationPath: DeskLocationItem[];
}

const options = [
  {
    icon: "reserve-icon",
    label: "Reserve",
    type: "reserve-workplace",
  },
  {
    icon: "open-map-icon",
    label: "Open on map",
    type: "open-on-map",
  },
];

export const MatchedWorkplaceActions = ({
  workspaceId,
  type,
  icon,
  locationPath,
}: Props) => {
  const ref = useRef(null);
  const { history } = useRouter();
  const [loading, setLoading] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const client = useApolloClient();
  const { user } = useAuthContext();
  const { timeZone } = useTimeZoneContext();
  const { personalCalendar, refetchPersonalCalendar } = useCalendarContext();
  const { validateCalendarIntegrationCredentials } = useCalendarRequests();
  const { allDay } = useBookingsMapContext();
  const {
    day,
    reservDeskVariables: { startTime, endTime },
    attendees,
    subject,
    handleSubject,
    handleModalClose,
    isOnlineMeeting,
    makePrivate,
    matchedRooms,
  } = useBookingsContext();
  const {
    isOpen,
    toggleModal,
    handleReserveDesk,
    handleReserveRoom,
    locationPath: locationOfWorkplace,
    organizerName,
  } = useReserveHelpers();

  const workplaceData = client.readFragment({
    id: `${capitalizeFirstLetter(type)}:${workspaceId}`,
    fragmentName: getWorkplaceFragmentName(capitalizeFirstLetter(type)),
    fragment: getWorkplaceFragment(capitalizeFirstLetter(type)),
  });

  const timeDifference = differenceInMinutes(
    utcToZonedTime(new Date(), timeZone),
    startTime
  );

  const validTimePassed = timeDifference <= 5 && timeDifference > 0;
  let zonedDay = zonedTimeToUtc(day, timeZone);

  let start = attachHoursToDate(
    utcToZonedTime(zonedDay, timeZone).toISOString(),
    validTimePassed ? utcToZonedTime(new Date(), timeZone) : startTime,
    timeZone
  );

  let end = attachHoursToDate(
    utcToZonedTime(zonedDay, timeZone).toISOString(),
    endTime,
    timeZone,
    allDay
  );

  const handleReserveAction = async (
    type: MatchedWorkplaceActionsType,
    typeOfWorkplace: MainResources
  ) => {
    setShowDropdown(false);
    setLoading(true);
    if (type === "reserve-workplace") {
      if (workplaceData.isBlocked) {
        setLoading(false);
        return toast.error(
          `Reservations blocked for ${typeOfWorkplace} due to admin policy`
        );
      }

      if (typeOfWorkplace === "desk") {
        await handleReserveDesk({
          accountId: user?.claims.user_id,
          customerID: user?.customerid || "",
          deskId: workspaceId,
          startTime: start,
          endTime: end,
          checkInReminderSend: workplaceData?.showCheckInTime,
        });
        setLoading(false);

        return;
      }

      let roomToReserve = matchedRooms?.filter(
        (room) => room.id === workspaceId
      );

      if (!roomToReserve) {
        return toast.error("Room couldn't be found!");
      }

      let hasPersonalCalendar = personalCalendar.length > 0;
      let statusOfPersonalIntegrationToken;

      if (hasPersonalCalendar) {
        try {
          await refetchPersonalCalendar();

          const {
            response: { status },
          } = await validateCalendarIntegrationCredentials(
            personalCalendar[0].iD
          );

          statusOfPersonalIntegrationToken = status;
        } catch (error: any) {
          toast.error(error?.message);
          return;
        }
      }

      if (
        hasPersonalCalendar &&
        statusOfPersonalIntegrationToken ===
          CalendarCredentialsStatus.CalendarCredentialsStatusUnknown
      ) {
        return toast.info(
          "Couldn't define the status of the personal integration, please contact your administrator."
        );
      }

      let hasValidPersonalToken =
        statusOfPersonalIntegrationToken ===
        CalendarCredentialsStatus.CalendarCredentialsStatusValid;

      let attendee: Attendee[] = [
        {
          displayName: "",
          email:
            hasPersonalCalendar && hasValidPersonalToken
              ? roomToReserve[0]?.resourceEmail
              : user?.email || "",
          firstName: "",
          id: "",
          isHuman: true,
          status: "",
          surName: "",
          allowToFind: true,
          mode1Organizer: true,
        },
      ];

      attendees.map((item) => {
        return attendee.push({
          displayName: "",
          email: item,
          firstName: "",
          id: "",
          isHuman: true,
          status: "",
          surName: "",
          allowToFind: true,
          mode1Organizer: true,
        });
      });

      await handleReserveRoom({
        attendees: attendee,
        calendarId:
          hasPersonalCalendar && hasValidPersonalToken
            ? ""
            : roomToReserve[0].calendarID,
        calendarproviderId:
          hasPersonalCalendar && hasValidPersonalToken
            ? personalCalendar[0].iD
            : roomToReserve[0].calendarProviderID,
        endTime: end,
        startTime: start,
        organizerEmail:
          hasPersonalCalendar && hasValidPersonalToken
            ? user?.email || ""
            : roomToReserve[0].resourceEmail,
        roomId: workspaceId,
        title: subject,
        isOnlineMeeting: isOnlineMeeting,
        isPrivate: makePrivate,
        checkInReminderSend: !!workplaceData?.displaySettings?.checkinReminder,
      });
      setLoading(false);

      if (roomToReserve[0].isExternal === true) {
        toast.info("EXTERNAL - the reservation status cannot be verified.");
      }

      return;
    }

    let workplaceParent = locationPath.filter(
      (workplace) => workplace.type === "Floor"
    );

    if (workplaceParent.length <= 0) {
      return toast.error("This workspace is not part of a floor!");
    }

    if (
      workplaceData.marker &&
      workplaceData.marker.latitude === 0 &&
      workplaceData.marker.latitude === 0
    ) {
      return toast.error(
        "Workspace not added to the floor plan map. Contact admin."
      );
    }

    history.push(
      `${RESERVATIONS_MAP_ROOT_PATH}/${workplaceParent[0].id}/markerOpen=${workspaceId}`
    );
  };

  const handleModalCloseHelper = () => {
    handleSubject("");
    toggleModal();
    handleModalClose();
  };

  useOutsideClickDetection(ref, () => {
    setShowDropdown(false);
  });

  const definedUsername = useMemo(() => {
    if (type === "desk" || !!!personalCalendar.length) {
      return user?.firstName + " " + user?.lastName;
    }

    return organizerName || "";
  }, [personalCalendar, organizerName]);

  return (
    <div ref={ref}>
      <div className="flex-a-center">
        <Button
          title={loading ? "Loading..." : "Reserve"}
          size="small"
          className="Reserve__button"
          onClick={() => handleReserveAction("reserve-workplace", type)}
        />
        <Icon
          icon="actions-icon"
          onClick={() => setShowDropdown((prev) => !prev)}
        />
      </div>

      {showDropdown && (
        <DropdownList
          extended
          color="gray"
          options={options}
          onClick={(value) =>
            handleReserveAction(value as MatchedWorkplaceActionsType, type)
          }
        />
      )}

      {isOpen && (
        <BookingsModal
          isOpen={isOpen}
          toggle={toggleModal}
          title="Reservation confirmed"
          icon="reservation-confimed"
          workspaceIconType={type === "desk" ? "desk-reserve" : "room-reserve"}
          day={formatReservationDate(utcToZonedTime(new Date(start), timeZone))}
          workspaceName={workplaceData.name}
          startTime={dateToHoursAndMinutes(new Date(start), timeZone)}
          endTime={dateToHoursAndMinutes(new Date(end), timeZone)}
          userName={definedUsername}
          subject={subject}
          locationPath={locationOfWorkplace}
          handleModalClose={handleModalCloseHelper}
          type="confirm"
          allDay={allDay}
        />
      )}
    </div>
  );
};
