import { differenceInMinutes, endOfDay } from "date-fns";

export const isEnoughTimeLeftInDay = (endTime: Date) => {
  const minutesUntilEndOfDay = differenceInMinutes(
    endOfDay(new Date()),
    endTime
  );
  return minutesUntilEndOfDay >= 15;
};

export const hasEnoughGapBetweenEvents = (
  endTime: Date,
  nextStartTime: Date
) => {
  const minutesBetweenEvents = differenceInMinutes(nextStartTime, endTime);
  return minutesBetweenEvents >= 15;
};
