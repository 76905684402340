import React from "react";
import { useReservationHelpers } from "../../Reservations/helpers/reservationUpdateHelpers";
import { useApolloClient } from "@apollo/client";
import { useTimeZoneContext } from "../../../../lib/context/TimeZone/TimeZoneContext";
import { useCalendarContext } from "../../../../lib/context/Calendar/CalendarContext";
import { utcToZonedTime } from "date-fns-tz";
import { useAuthContext } from "../../../../lib/context/Auth/AuthContext";

import { Event as DeskEvent } from "../../../../api/grpc/desk/ggevent/ggevent";
import {
  CheckInRequiredStatus,
  Event as RoomEvent,
} from "../../../../api/grpc/booking/ggevent/ggevent";
import { dateToHoursAndMinutes } from "../../lib/dateInputConvert";
import { useFormatReservationDate } from "../../lib/datePickerHelper";
import { BookingsModal } from "../../shared/BookingsModal/BookingsModal";
import { capitalizeFirstLetter } from "../../../../lib/helpers/capitalizeFirstLetter";
import {
  getWorkplaceFragment,
  getWorkplaceFragmentName,
} from "../../../Workplaces/helpers/getWorkplaceFragment";
import { ExtendedProps } from "../../Reservations/ReservationList/ReservationListActions";

export const DeleteModal = ({
  type,
  workplaceId,
  event,
  startTime,
  endTime,
  name,
  locationPath,
  isOpen,
  toggleModal,
  userName,
  refetchDesk,
  refetchRoom,
  allowToModifyEvent,
}: ExtendedProps) => {
  const client = useApolloClient();
  const { timeZone } = useTimeZoneContext();
  const { personalCalendar } = useCalendarContext();
  const { user } = useAuthContext();

  const { deleteDeskReservation, deleteRoomReservation } =
    useReservationHelpers();

  const workplaceData = client.readFragment({
    id: `${capitalizeFirstLetter(type)}:${workplaceId}`,
    fragmentName: getWorkplaceFragmentName(capitalizeFirstLetter(type)),
    fragment: getWorkplaceFragment(capitalizeFirstLetter(type)),
  });

  const definedUsername =
    type === "desk" || !!!personalCalendar.length
      ? userName
      : event.organizer?.displayName || "";

  const handleDeleteReservation = async () => {
    if (type === "desk" && refetchDesk) {
      return await deleteDeskReservation(
        workplaceId,
        event.metaData.DESK_EVENT_ID,
        refetchDesk
      );
    }

    if (refetchRoom) {
      return await deleteRoomReservation(
        personalCalendar.length > 0 ? "" : workplaceData?.calendarId,
        personalCalendar.length > 0
          ? personalCalendar[0].iD
          : workplaceData?.calendarProviderId,
        event.metaData,
        refetchRoom,
        event.checkInRequiredStatus === CheckInRequiredStatus.CHECK_IN_REQUIRED,
        user?.email || ""
      );
    }
  };

  return (
    <BookingsModal
      isOpen={isOpen}
      toggle={toggleModal}
      title="Delete reservation?"
      icon="delete-reservation"
      workspaceIconType={type === "desk" ? "desk-reserve" : "room-reserve"}
      workspaceName={name}
      day={useFormatReservationDate(
        utcToZonedTime(new Date(startTime), timeZone)
      )}
      // day={useFormatReservationDate(new Date(startTime))}
      startTime={dateToHoursAndMinutes(new Date(startTime), timeZone)}
      endTime={
        type === "desk" && event.allDay === true
          ? "00:00"
          : dateToHoursAndMinutes(new Date(endTime), timeZone)
      }
      userName={definedUsername}
      locationPath={locationPath}
      type="delete"
      buttonClick={handleDeleteReservation}
      buttonTitle="Delete"
      handleModalClose={toggleModal}
      classNames="modal-footer-row"
      subject={event?.title}
      allowToModifyEvent={allowToModifyEvent}
    />
  );
};
