import React, { PropsWithChildren } from "react";
import useGrpcQuery from "../../../../lib/hooks/useGrpcQuery";
import { useRouter } from "../../../../lib/hooks/useRouter";
import { useContacts } from "../../../../api/grpc/contacts/useContacts";
import { useCalendarContext } from "../../../../lib/context/Calendar/CalendarContext";

import { OrganizerFilter } from "../../Context/Room/Overview/RoomInsightsContextProvider";
import { Popup } from "../../../shared/Popup/Popup";
import { Icon } from "../../../shared/Icon/Icon";
import { HistoryOrganizers } from "./HistoryOrganizers";
import {
  ListPeopleFilter,
  ListPeopleRequest,
  People,
} from "../../../../api/grpc/contacts/contacts";

export const HistoryFilters = ({
  disbaled,
  organizers,
  setOrganizers,
}: PropsWithChildren<{
  disbaled: boolean;
  organizers?: OrganizerFilter[];
  setOrganizers: React.Dispatch<
    React.SetStateAction<OrganizerFilter[] | undefined>
  >;
}>) => {
  const { listPeople } = useContacts();
  const { personalCalendar } = useCalendarContext();
  const { pathname } = useRouter();

  const { loading, error, data } = useGrpcQuery<ListPeopleRequest, People>({
    method: listPeople,
    variables: {
      personalCalendarID: pathname.includes("desk")
        ? ""
        : personalCalendar[0]?.iD || "",
      customerID: "",
      query: "",
      accountID: "",
      filter: ListPeopleFilter.ALL,
    },
  });

  return (
    <Popup
      className={`HistoryFilters ${disbaled ? "disabled" : ""}`}
      trigger={() => (
        <div className="HistoryFilters flex-a-center">
          <Icon
            icon="filter-icon"
            className={organizers?.length ? "active-icon" : ""}
          ></Icon>
          <h4 className={organizers?.length ? "link" : ""}>
            Organizer {organizers?.length ? `(${organizers.length})` : ""}
          </h4>
        </div>
      )}
      position="bottom right"
      title="Spotlight people"
      disabled={disbaled}
    >
      <HistoryOrganizers
        loading={loading}
        error={error}
        data={data}
        organizers={organizers}
        setOrganizers={setOrganizers}
      />
    </Popup>
  );
};
