import React from "react";
import { MainResources } from "../../../../lib/types/main.types";
import { ReservationCardHeader } from "./ReservationCardHeader";
import { AddReservation } from "./AddReservation";
import { ReservationEvent } from "./ReservationEvent";
import {
  DeskBookingEvent,
  DeskLocationItem,
} from "../../../../api/grpc/desk/desk";
import { differenceInMinutes, endOfDay } from "date-fns";
import {
  hasEnoughGapBetweenEvents,
  isEnoughTimeLeftInDay,
} from "../../../../lib/helpers/dateHelpers";
import { useToggleModal } from "../../../../lib/hooks/useToggleModal";
import { BookingsButtonsModal } from "../../../Bookings/BookingsMap/BookingsMapModal/BookingsButtonsModal";

interface Props {
  type: MainResources;
  name: string;
  tags: string;
  events: DeskBookingEvent[];
  locationPath: DeskLocationItem[];
  workplaceID: string;
  refetchDesk?: () => void;
}

export const ReservationCard = ({
  type,
  name,
  tags,
  locationPath,
  events,
  workplaceID,
  refetchDesk,
}: Props) => {
  const { isOpen, toggleModal } = useToggleModal();
  const hasEvents = events.length > 0;

  return (
    <div className="ReservationCard default-box">
      {isOpen && (
        <BookingsButtonsModal
          isOpen={isOpen}
          id={workplaceID}
          busySlots={[]}
          toggleModal={toggleModal}
          type="add"
          typeOfWorkplace="desk"
          refetch={() => {
            if (refetchDesk) {
              refetchDesk();
            }
          }}
        />
      )}

      <ReservationCardHeader
        type={type}
        name={name}
        tags={tags}
        locationPath={locationPath}
      />

      {hasEvents ? (
        events
          .sort(
            (el1, el2) =>
              +new Date(el1.event?.startTime ?? "") -
              +new Date(el2.event?.endTime ?? "")
          )
          .map((event, index) => (
            <div key={`${index}-${event.event?.workplaceID}`}>
              {event.event && (
                <ReservationEvent
                  type="desk"
                  event={event.event}
                  workplaceID={workplaceID}
                  workspaceName={name}
                  refetchDesk={refetchDesk}
                />
              )}

              {index < events.length - 1 &&
                event.event &&
                events[index + 1]?.event &&
                hasEnoughGapBetweenEvents(
                  new Date(event.event.endTime),
                  new Date(events[index + 1].event!.startTime)
                ) && <AddReservation handleClick={toggleModal} />}
            </div>
          ))
      ) : (
        <h6 className="text-center">No bookings found</h6>
      )}

      {hasEvents &&
        events[events.length - 1].event &&
        isEnoughTimeLeftInDay(
          new Date(events[events.length - 1].event!.endTime)
        ) && <AddReservation handleClick={toggleModal} />}

      {!hasEvents && isEnoughTimeLeftInDay(new Date()) && (
        <AddReservation handleClick={toggleModal} />
      )}
    </div>
  );
};
