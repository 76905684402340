import React, { useState } from "react";
import { WorkplaceFields } from "../../../../api/graphql/workplaces";
import { useRouter } from "../../../../lib/hooks/useRouter";
import { getWorkplaceIcon } from "../../../Workplaces/helpers/getWorkplaceInformation";
import { getWorkplaceChildren } from "../../../Workplaces/helpers/getWorkplaceChildren";
import cs from "classnames";
import { NavLink } from "react-router-dom";
import { Icon } from "../../../shared/Icon/Icon";
import { toast } from "react-toastify";

interface Props {
  item: WorkplaceFields;
  toggleParent: (value: boolean) => void;
  pickedWorkspaces: WorkplaceFields[];
  innerWorkspaces: WorkplaceFields[];
  setPickedWorkspaces: React.Dispatch<React.SetStateAction<WorkplaceFields[]>>;
  setInnerWorkspaces: React.Dispatch<React.SetStateAction<WorkplaceFields[]>>;
}

export const AllReservationTreeLevel = (props: Props) => {
  const {
    item,
    toggleParent,
    pickedWorkspaces,
    setPickedWorkspaces,
    innerWorkspaces,
    setInnerWorkspaces,
  } = props;

  const { pathname } = useRouter();
  const isActive = pathname.includes(item.id);

  const [toggle, setToggle] = useState(() => isActive);

  const icon = getWorkplaceIcon(item.__typename);
  const workplaceChildren = getWorkplaceChildren(item);
  const hasChildren = workplaceChildren.some((item) => item && item.length > 0);

  const handleToggle = () => {
    setToggle((prev) => !prev);
  };

  const classes = cs("WorkplacesTreeLevel", {
    "WorkplacesTreeLevel--open": toggle,
    "WorkplacesTreeLevel--active": isActive,
    "WorkplacesTreeLevel--space": !hasChildren,
  });

  const subLevelClasses = cs("WorkplacesTreeLevel__sub-level", {
    "WorkplacesTreeLevel__sub-level--open": toggle,
  });
  return (
    <>
      <div className={classes}>
        <div className="WorkplacesTreeLevel__wrapper">
          {hasChildren && (
            <span
              onClick={handleToggle}
              className="WorkplacesTreeLevel__toggle"
            ></span>
          )}
          <NavLink
            className="WorkplacesTreeLevel__link"
            onClick={() => {}}
            to={"#"}
          >
            <div className="WorkplacesTreeLevel__icon">
              <Icon icon={icon} />
            </div>
            <h3 className="WorkplacesTreeLevel__name">{item.name}</h3>
          </NavLink>
          {item.__typename === "Desk" || item.__typename === "Room" ? (
            <>
              <div
                className="WorkplacesTreeLevel__status"
                onClick={() => {
                  let typeOfMethod: "add" | "delete" = innerWorkspaces.some(
                    (workspace) => workspace.id === item.id
                  )
                    ? "delete"
                    : "add";

                  if (typeOfMethod === "add" && innerWorkspaces.length >= 5) {
                    return toast.warn(
                      "You have reached the maximum number allowed for calendars"
                    );
                  }

                  setInnerWorkspaces((prev) => {
                    if (typeOfMethod === "add") {
                      return [...prev, item];
                    }

                    return prev.filter(
                      (workplaceItem) => workplaceItem.id !== item.id
                    );
                  });
                }}
              >
                <Icon
                  icon={
                    innerWorkspaces.some(
                      (formikItem) => formikItem.id === item.id
                    )
                      ? "checkbox-full"
                      : "checkbox-empty"
                  }
                />
              </div>
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
      {hasChildren && (
        <div className={subLevelClasses}>
          {workplaceChildren.map((type) => {
            return type?.map((workplace) => (
              <AllReservationTreeLevel
                item={workplace}
                key={`${workplace.id}-tree-level`}
                toggleParent={(value) => {
                  setToggle(value);
                  toggleParent(value);
                }}
                pickedWorkspaces={pickedWorkspaces}
                setPickedWorkspaces={setPickedWorkspaces}
                innerWorkspaces={innerWorkspaces}
                setInnerWorkspaces={setInnerWorkspaces}
              />
            ));
          })}
        </div>
      )}
    </>
  );
};
