import React, { useState } from "react";
import { Form, FormGroup } from "reactstrap";
import Select from "react-select";
import cs from "classnames";

import { useCustomerRequests } from "../../../../api/grpc/customer/useCustomerRequests";
import { useLicensesContext } from "../../../../lib/context/Customer/CustomerContext";
import { useGlobalWorkingHours } from "../../../../lib/hooks/useGlobalWorkingHours";

import { Input } from "../../Input/Input";
import { Switch } from "../../Switch/Switch";
import { TagInput } from "../../TagInput/TagInput";
import {
  LabelDirectionType,
  LabelWithInformationBox,
} from "../../LabelWithInformationBox/LabelWithInformationBox";

import { tooltips } from "../../../../lib/utils/tooltips";
import { selectStyles } from "../../../../lib/utils/selectStyles";

import { DeskFormik } from "./lib/useDeskFormik";
import { EntityType } from "../../../../api/grpc/subscription/subscription";
import { CheckInTime } from "../../../../api/graphql/desks/desks";
import { UserListSelect } from "./UserListSelect/UserListSelect";
import { BulkDesksForm } from "./BulkDesksForm/BulkDesksForm";
import { WorkingHours } from "../DisplaySettingsForm/shared/WorkingHours";

import {
  dateFormat,
  dateHelper,
} from "../DisplaySettingsForm/lib/displayDateHelper";
import { LoadingBox } from "../../LoadingBox/LoadingBox";
import { ContactsListResponse } from "../../../../api/grpc/contacts/contacts";
import { InputDescription } from "../../InputDescription/InputDescription";
import { FormSubSection } from "../../FormSubSection/FormSubSection";
import { CreateDeskFromTemplate } from "./CreateDeskFromTemplate/CreateDeskFromTemplate";

interface Props {
  id?: string;
  edit?: boolean;
  informationBoxDirection?: LabelDirectionType;
  formik: DeskFormik;
  downloadQRicon?: JSX.Element;
  hasBulkDesks?: boolean;
  loading: boolean;
  error?: Error;
  data?: ContactsListResponse;
  displayEditWorkHours?: boolean;
  isProfile?: boolean;
  className?: string;
  defineLicenseNumber?: () => JSX.Element;
  refetchLicenseNumber?: () => void;
  fillFieldsFromProfile?: (id: string) => void;
}

export const checkInTimeOptions = [
  { label: "1 hour", value: 1 },
  { label: "2 hours", value: 2 },
  { label: "3 hours", value: 3 },
  { label: "4 hours", value: 4 },
];

export const DeskForm = ({
  formik,
  edit,
  id,
  informationBoxDirection,
  downloadQRicon,
  hasBulkDesks,
  loading: loadingContact,
  error,
  data,
  displayEditWorkHours,
  isProfile,
  className,
  defineLicenseNumber,
  refetchLicenseNumber,
  fillFieldsFromProfile,
}: Props) => {
  const { disabled, spinner, handleLicenseUpdate } = useLicensesContext();
  const [isOpenBulkDesks, setIsOpenBulkDesks] = useState(false);

  const { updateCustomerSettings } = useCustomerRequests();

  const {
    loading,
    error: workingHoursError,
    customerSettings,
    refetch,
  } = useGlobalWorkingHours();

  const handleBulkDesksOpen = () => {
    setIsOpenBulkDesks((prev) => !prev);
  };

  const classes = cs("DeskForm", className);

  return (
    <Form className={classes}>
      {!isProfile && (
        <FormGroup>
          <LabelWithInformationBox
            for="name"
            title="Name"
            message={tooltips.workplaces.name}
            direction={informationBoxDirection}
          />
          <Input
            id="name"
            name="name"
            type="text"
            value={isOpenBulkDesks ? "" : formik.values.name}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            isTouched={formik.touched.name}
            errorMessage={!isOpenBulkDesks ? formik.errors.name : undefined}
            invalid={!!formik.errors.name && formik.touched.name}
            disabled={isOpenBulkDesks}
          />
        </FormGroup>
      )}

      {hasBulkDesks && (
        <BulkDesksForm
          formik={formik}
          informationBoxDirection={informationBoxDirection}
          onClick={handleBulkDesksOpen}
          isBulkDesksOpen={isOpenBulkDesks}
        />
      )}

      {!isProfile && !edit && (
        <CreateDeskFromTemplate
          fillFieldsFromProfile={fillFieldsFromProfile}
          resetFormikFields={formik.resetForm}
        />
      )}

      <FormGroup>
        <LabelWithInformationBox
          for="description"
          title="Description"
          message={tooltips.workplaces.description}
          direction={informationBoxDirection}
        />
        <Input
          type="text"
          id="description"
          name="description"
          onBlur={formik.handleBlur}
          value={formik.values.description}
          onChange={formik.handleChange}
        />
      </FormGroup>
      <TagInput
        value={formik.values.tags || []}
        direction={informationBoxDirection}
        onChange={(tags) => {
          formik.setFieldValue("tags", tags);
          formik.setFieldTouched("tags");
        }}
      />

      <Switch
        name="isBlocked"
        title="Block desk"
        message={tooltips.desks.blockDesk}
        value={formik.values.isBlocked}
        onChange={(value) => {
          formik.setFieldValue("isBlocked", value);
          formik.setFieldTouched("isBlocked", true);
          return;
        }}
        disabled={isOpenBulkDesks}
        direction={informationBoxDirection}
        paddingBottom={true}
      />

      <FormGroup>
        <LabelWithInformationBox
          for="account"
          title="Assign user (optional)"
          message={tooltips.desks.assignedUser}
          direction={informationBoxDirection}
          className="mt-2"
        />
        <UserListSelect
          isDisabled={isOpenBulkDesks}
          onChange={(value: string, label: string) => {
            formik.setFieldValue("account", {
              accountID: value,
              displayName: label,
            });
            formik.setFieldTouched("account", true);
            return;
          }}
          value={{
            value: formik?.values?.account?.accountID || "",
            label: formik?.values?.account?.displayName || "",
          }}
          errorMessage={String(formik.errors.account) || ""}
          invalid={
            Boolean(!!formik.errors.account && formik.touched.account) || false
          }
          loading={loadingContact}
          error={error}
          data={data}
        />
      </FormGroup>

      <Switch
        name="showCheckInTime"
        title="Check-in required"
        message={tooltips.desks.checkIn}
        value={formik.values.showCheckInTime}
        onChange={(value) => {
          formik.setFieldValue("showCheckInTime", value);
          formik.setFieldTouched("showCheckInTime", true);
          return;
        }}
        direction={informationBoxDirection}
        paddingBottom={true}
      >
        <FormGroup>
          <LabelWithInformationBox
            for="checkInTime"
            title="Check-in time window"
            message={tooltips.desks.checkInTime}
            direction={informationBoxDirection}
          />
          <Select
            id="checkInTime"
            isSearchable={true}
            name="checkInTime"
            theme={selectStyles}
            options={checkInTimeOptions}
            onChange={(e) => {
              if (!e) {
                return;
              }

              formik.setFieldValue("checkInTime", e.value);
              formik.setFieldTouched("checkInTime");
            }}
            value={checkInTimeOptions.filter(
              (item) =>
                formik.values.checkInTime === (item.value as CheckInTime)
            )}
          />
        </FormGroup>
        <Switch
          name="qrCodeRequired"
          title="QR code"
          message={tooltips.desks.qrCode}
          value={formik.values.qrCodeRequired}
          onChange={(value) => {
            formik.setFieldValue("qrCodeRequired", value);
            formik.setFieldTouched("qrCodeRequired", true);
            return;
          }}
          direction={informationBoxDirection}
          paddingTop={true}
          icon={downloadQRicon}
        />
      </Switch>

      {(edit || isProfile) && (
        <Switch
          name="workingHrsActivated"
          title="Schedule only during work hours"
          message={tooltips.desks.workingHrsActivated}
          value={formik.values.workingHrsActivated}
          onChange={(value) => {
            formik.setFieldValue("workingHrsActivated", value);
            formik.setFieldTouched("workingHrsActivated", true);
            return;
          }}
          direction={informationBoxDirection}
          paddingBottom={true}
        >
          <FormSubSection className={displayEditWorkHours ? "" : "disabled"}>
            {loading ? (
              <LoadingBox minHeight={50} />
            ) : workingHoursError || !customerSettings ? (
              <div>Error fetching working hours data</div>
            ) : (
              <>
                {!displayEditWorkHours && !isProfile && (
                  <InputDescription
                    title="Press Save in order to change working hours & days."
                    className="custom-font mb-3"
                  />
                )}
                <WorkingHours
                  id={id}
                  loading={loading}
                  startHours={dateHelper(
                    formik.values.workingHrsStart || "",
                    "startTime",
                    customerSettings
                  )}
                  endHours={dateHelper(
                    formik.values.workingHrsEnd || "",
                    "endTime",
                    customerSettings
                  )}
                  handleStartHours={(value) => {
                    formik.setFieldValue("workingHrsStart", value);
                    formik.setFieldTouched("workingHrsStart", true);
                    if (!!formik.values.workingHrsEnd === false) {
                      formik.setFieldValue(
                        "workingHrsEnd",
                        dateFormat(customerSettings?.endTime || "")
                      );
                      formik.setFieldTouched("workingHrsEnd", true);
                    }

                    return;
                  }}
                  handleEndHours={(value) => {
                    formik.setFieldValue("workingHrsEnd", value);
                    formik.setFieldTouched("workingHrsEnd", true);

                    if (!!formik.values.workingHrsStart === false) {
                      formik.setFieldValue(
                        "workingHrsStart",
                        dateFormat(customerSettings?.startTime || "")
                      );
                      formik.setFieldTouched("workingHrsStart", true);
                    }

                    return;
                  }}
                  isGlobal={
                    !!formik.values.workingHrsStart ||
                    !!formik.values.workingHrsEnd
                  }
                  isProfile={isProfile}
                  customerSettings={customerSettings}
                  onChange={() => {
                    formik.setFieldValue("workingHrsStart", null);
                    formik.setFieldTouched("workingHrsStart", true);

                    formik.setFieldValue("workingHrsEnd", null);
                    formik.setFieldTouched("workingHrsEnd", true);

                    return;
                  }}
                  updateCustomerSettings={updateCustomerSettings}
                  refetch={refetch}
                  disabledWorkingHours={!displayEditWorkHours}
                />
              </>
            )}
          </FormSubSection>
        </Switch>
      )}

      {!isProfile && (
        <>
          <Switch
            name="licensed"
            disabled={disabled}
            spinner={spinner}
            title="Assign license"
            direction={informationBoxDirection}
            value={formik.values.licensed}
            message={tooltips.desks.license}
            paddingTop
            onChange={async (value) => {
              if (!edit) {
                formik.setFieldValue("licensed", value);
                formik.setFieldTouched("licensed", true);
                return;
              }

              if (!id) {
                return;
              }

              await handleLicenseUpdate({
                id,
                value,
                type: EntityType.DESK,
              });

              if (refetchLicenseNumber) {
                return refetchLicenseNumber();
              }
            }}
          />

          {defineLicenseNumber && defineLicenseNumber()}
        </>
      )}
    </Form>
  );
};
