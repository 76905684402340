import { Event } from "../../api/grpc/desk/ggevent/ggevent";

// this object defined event data that are static
export const deskEvent: Event = {
  title: "Desk Reservation",
  description: "description",
  locations: [],
  attendees: [],
  organizer: undefined,
  isPrivate: false,
  extendedProperties: {},
  metaData: {},
  creationTime: "",
  providerType: "",
  updateTime: "",
  workplaceID: "",
  checkInRequiredStatus: 1,
  eventCheckInStatus: 1,
  workplaceLocationPath: [],
  tags: [],
  isOnlineMeeting: false,
  onlineMeetingUrl: "",
  isCheckOut: false,
  headcount: 0,
  lockPin: "",

  //dynamic data below that will be owerriten:
  allDay: false,
  checkInReminderSend: false,
  endTime: "",
  eventTimeZone: "",
  startTime: "",
};
