import React, { PropsWithChildren } from "react";
import { Circle, FeatureGroup, Polygon, Popup } from "react-leaflet";
import { useAuthContext } from "../../../../lib/context/Auth/AuthContext";

import { ShapeTooltip } from "./ShapeTooltip";
import { FloorChildrenType } from "../../../Floors/lib/floorData";
import { BookingsMapPopUp } from "../BookingsMapMarkers/BookingsMapPopUp";
import { MapEntityModal } from "../BookingsFloorMap/BookingsFloorMap";

export const BookingsMapShapes = ({
  circles,
  polygonal,
  showRoomMarkers,
  showTooltips,
  handleShapeClick,
  handleMapEntityReservePress,
  room,
}: PropsWithChildren<{
  circles: L.Circle<any>[];
  polygonal: L.Polygon<any>[];
  showRoomMarkers: boolean;
  showTooltips: boolean;
  handleShapeClick: (id: string) => void;
  handleMapEntityReservePress: ({
    id,
    type,
    modalType,
    busySlots,
  }: MapEntityModal) => void;
  room?: FloorChildrenType;
}>) => {
  const { user } = useAuthContext();

  return (
    <FeatureGroup>
      {circles.map((circle) => {
        const shapeId = circle.options.attribution?.split(
          "-innerGoGetIDSplitter-"
        )[0];

        if (!shapeId) return null;

        return (
          <div key={shapeId}>
            {room && (
              <Popup>
                <BookingsMapPopUp
                  id={room.id}
                  isBooked={room.isBooked || false}
                  isAssigned={false}
                  isBlocked={room.isBlocked || false}
                  type={"Room"}
                  name={room.name}
                  tags={room.tags}
                  busySlots={room.busySlots || []}
                  email={user?.email}
                  handleOpenModal={() =>
                    handleMapEntityReservePress({
                      id: room.id,
                      busySlots: room.busySlots || [],
                      type: "room",
                      modalType: room.isBooked ? "delete" : "add",
                    })
                  }
                />
              </Popup>
            )}
            <Circle
              center={circle.getLatLng()}
              radius={circle.getRadius()}
              color={circle.options.color}
              fillOpacity={circle.options.fillOpacity}
              fillColor={circle.options.fillColor}
              weight={2}
              // className={
              //   activeShape ===
              //   circle.options.attribution?.split("-innerGoGetIDSplitter-")[0]
              //     ? "stroke-dasharray"
              //     : ""
              // }
              eventHandlers={{
                click: () => {
                  handleShapeClick(shapeId);
                },
              }}
            >
              <>
                {!showRoomMarkers && showTooltips && (
                  <>
                    <ShapeTooltip
                      name={
                        circle.options.attribution?.split(
                          "-innerGoGetIDSplitter-"
                        )[1] || ""
                      }
                      className={circle.options.className}
                    />
                  </>
                )}
              </>
            </Circle>
          </div>
        );
      })}

      {polygonal.map((polygonal) => {
        const shapeId = polygonal.options.attribution?.split(
          "-innerGoGetIDSplitter-"
        )[0];

        if (!shapeId) return null;

        return (
          <div key={shapeId}>
            {room && (
              <Popup>
                <BookingsMapPopUp
                  id={room.id}
                  isBooked={room.isBooked || false}
                  isAssigned={false}
                  isBlocked={room.isBlocked || false}
                  type={"Room"}
                  name={room.name}
                  tags={room.tags}
                  busySlots={room.busySlots || []}
                  email={user?.email}
                  handleOpenModal={() =>
                    handleMapEntityReservePress({
                      id: room.id,
                      busySlots: room.busySlots || [],
                      type: "room",
                      modalType: room.isBooked ? "delete" : "add",
                    })
                  }
                />
              </Popup>
            )}

            <Polygon
              // key={shapeId}
              positions={polygonal.getLatLngs()}
              color={polygonal.options.color}
              fillOpacity={polygonal.options.fillOpacity}
              fillColor={polygonal.options.fillColor}
              weight={2}
              eventHandlers={{
                click: () => {
                  handleShapeClick(shapeId);
                },
              }}
            >
              <>
                {!showRoomMarkers && showTooltips && (
                  <ShapeTooltip
                    name={
                      polygonal.options.attribution?.split(
                        "-innerGoGetIDSplitter-"
                      )[1] || ""
                    }
                    className={polygonal.options.className}
                  />
                )}
              </>
            </Polygon>
          </div>
        );
      })}
    </FeatureGroup>
  );
};
